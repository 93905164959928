import React, { useState } from 'react';
import { Loading, ModalDefault } from '../../../shared/components/Modal/ModalDefault';
import { Form } from 'react-bootstrap';
import { PRIMARY_COLOR, ROLES, SystemRoles } from '../../../shared/constants';
import { useCallAction, useEvent, useFetchAction } from '@cobuildlab/react-simple-state';
import { createUserInvitation, openInviteUserDialog } from '../user-actions';
import { showToast } from '../../../shared/components/toast/toast';
import { openInviteUserDialogEvent } from '../user-events';
import { fetchEvents } from '../../events/event-actions';
import { EventType } from '../../events/event-types';
import { Controller, useForm } from 'react-hook-form';
import validator from 'validator';
import { ButtonDefault } from '../../../shared/components/Buttons/ButtonDefault';
import { UserInvitationType } from '../user-types';
import Select from 'react-select/base';
import { ReactSelectOption } from '../../../shared/components/ReactSelect/ReactSelectOption';

type InviteUserDialogProps = {
  onSuccess: () => void;
}

export const InviteUserDialog: React.FC<InviteUserDialogProps> = (
  {
    onSuccess,
  }) => {
  const { handleSubmit, control, reset } = useForm();
  const { isOpen } = useEvent(openInviteUserDialogEvent);
  const [categories, setCategories] = useState<{ value: string; label: string }[]>([]);
  const [categoryValues, setCategoryValues] = useState<{ value: number, label: string }[]>([]);
  const [createInvitationSubmit, loading] = useCallAction(createUserInvitation, {
    onCompleted: () => {
      showToast('success', 'Usuario se ha invitado correctamente!');
      openInviteUserDialog(false);
      reset();
      onSuccess();
      setCategoryValues([]);
      setCategories([]);
    },
    onError: (error) => {
      showToast('error', error.message);
    },
  });

  const [{ events }] = useFetchAction(fetchEvents, [1, 100]);
  const [open, setOpen] = useState<boolean>(false);

  return (
    <ModalDefault
      show={isOpen}
      title='Invitar usuarios'
      textClose='Cerrar'
      textSave='Invitar'
      isLoading={loading}
      showFooterButtons={false}
      size="lg"
    >
      <>

        <form onSubmit={handleSubmit((values) => {
          createInvitationSubmit({ ...values, categories: categoryValues } as UserInvitationType);
        })}>
          <div className='row mb-2'>
            <div className='col-md-6'>
              <Controller
                name='first_name'
                control={control}
                defaultValue=''
                rules={{
                  required: 'El nombre es requerido',
                }}
                render={
                  ({
                     field: { onChange, value },
                     fieldState: { invalid, error },
                   }) => (
                    <Form.Group className='mb-3'>
                      <Form.Control placeholder='Nombre' onChange={onChange} value={value} />
                      {
                        invalid && (
                          <Form.Text className='text-danger'>
                            {error?.message}
                          </Form.Text>
                        )
                      }
                    </Form.Group>
                  )}
              />

              <Controller
                name='last_name'
                control={control}
                defaultValue=''
                rules={{
                  required: 'El apellido es requerido',
                }}
                render={
                  ({
                     field: { onChange, value },
                     fieldState: { invalid, error },
                   }) => (
                    <Form.Group className='mb-3'>
                      <Form.Control placeholder='Apellido' onChange={onChange} value={value} />
                      {
                        invalid && (
                          <Form.Text className='text-danger'>
                            {error?.message}
                          </Form.Text>
                        )
                      }
                    </Form.Group>
                  )}
              />


              <Controller
                name='email'
                control={control}
                defaultValue=''
                rules={{
                  required: 'El correo es requerido',
                  validate: (email) => {

                    if (!validator.isEmail(email)) {
                      return 'Insertar email valido';
                    }
                    return true;
                  },
                }}
                render={
                  ({
                     field: { onChange, value },
                     fieldState: { invalid, error },
                   }) => (
                    <Form.Group className='mb-3'>
                      <Form.Control placeholder='Correo' onChange={onChange} value={value} />
                      {
                        invalid && (
                          <Form.Text className='text-danger'>
                            {error?.message}
                          </Form.Text>
                        )
                      }
                    </Form.Group>
                  )}
              />
            </div>
            <div className='col-md-6'>

              <Controller
                name='role'
                control={control}
                defaultValue=''
                rules={{
                  required: 'El role es requerido',
                }}

                render={
                  ({
                     field: { onChange, value },
                     fieldState: { invalid, error },

                   }) => (
                    <Form.Group className='mb-3'>
                      <Form.Select onChange={onChange} value={value} placeholder='Rol'>
                        <option value=''>Seleccionar rol</option>
                        {
                          ROLES.map((role) => (
                            <option value={role.value}>{role.label}</option>
                          ))
                        }
                      </Form.Select>
                      {
                        invalid && (
                          <Form.Text className='text-danger'>
                            {error?.message}
                          </Form.Text>
                        )
                      }
                    </Form.Group>
                  )}
              />

              <Controller
                name='event'
                control={control}
                defaultValue=''
                render={
                  ({
                     field: { onChange, value },
                     fieldState: { invalid, error },

                   }) => (
                    <Form.Group className='mb-3'>
                      <Form.Select onChange={(e) => {
                        onChange(e);
                        const selectedEvent = events.find((event: EventType) => event.id === e.target.value);
                        if (selectedEvent) setCategories(selectedEvent.categories);
                      }} value={value} placeholder='Convocatoria'>
                        <option value=''>Seleccionar convocatoria</option>
                        {
                          events.map((event: EventType) => (
                            <option value={event?.id}>{event?.name}</option>
                          ))
                        }
                      </Form.Select>
                      {
                        invalid && (
                          <Form.Text className='text-danger'>
                            {error?.message}
                          </Form.Text>
                        )
                      }
                    </Form.Group>
                  )}
              />

              <Controller
                name='categories'
                control={control}
                defaultValue={categoryValues}
                render={
                  ({
                     field: { onChange, value },
                     fieldState: { invalid, error },

                   }) => (
                    <Form.Group>
                      <Select
                        placeholder='Categorias'
                        isMulti
                        isDisabled={!categories.length}
                        isLoading={false}
                        value={categoryValues}
                        options={categories.map((category: { value: string; label: string }) => ({
                          value: parseInt(category.value),
                          label: category.label as string,
                        }))}
                        onChange={(categoryData) => {
                          setCategoryValues([...categoryData as []]);
                        }}
                        components={{
                          Option: function OptionCustom(props) {
                            return (
                              <ReactSelectOption
                                reactSelectProps={props}
                                defaultValues={categoryValues}
                              />
                            );
                          },
                        }}
                        inputValue=''
                        menuIsOpen={open}
                        onInputChange={() => {
                        }}
                        onMenuClose={() => setOpen(false)}
                        onMenuOpen={() => setOpen(true)}
                      />
                      {
                        invalid && (
                          <Form.Text className='text-danger'>
                            {error?.message}
                          </Form.Text>
                        )
                      }
                    </Form.Group>
                  )}
              />
            </div>
          </div>




          <Form.Group className='mb-3 text-center'>
            <ButtonDefault
              style={{ backgroundColor: PRIMARY_COLOR, color: 'white', minWidth: 100 }}
              onClick={() => openInviteUserDialog(false)}
              className='text-uppercase px-4 py-2 mx-2'
            >
              Cerrar
            </ButtonDefault>

            <ButtonDefault
              className='text-uppercase px-4 py-2'
              disabled={loading}
              type='submit'
            >
              {loading && <Loading />}
              Invitar
            </ButtonDefault>

          </Form.Group>
        </form>
      </>
    </ModalDefault>

  );
};