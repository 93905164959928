import React from 'react';
import { Button, ButtonProps, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { PRIMARY_COLOR } from '../../constants';

interface ButtonDefaultProps extends ButtonProps {
  isLoading?: boolean | undefined;
}

const MainButton = styled(Button)`
  background-color: transparent;
  border-radius: 20px;
  padding: 0.20rem 0.5rem;
  box-shadow: none;
  border: 2px solid white;
  height:40px;
  width:165px;
  text-transform: uppercase;
  color:white;
  margin: 0 1rem;
  &:focus, &:active{
    box-shadow: none!important;
  }
  
  & > svg{
    fill:${PRIMARY_COLOR}!important;
  }
  
  &:hover, &:active, &:focus, &:before, &:after{
    background-color: white;
    color:${PRIMARY_COLOR};
  }
`;

const Loading: React.FC = () => {
  return (
    <Spinner
      as='span'
      animation='border'
      size='sm'
      role='status'
      aria-hidden='true'
      className='mx-2'
    />
  );
};

export const TableButtonDefault: React.FC<ButtonDefaultProps> = (
  {
    isLoading,
    children,
    disabled,
    ...rest
  },
) => {
  return (
    <MainButton
      disabled={isLoading || disabled}
      {...rest}
    >
      {isLoading && <Loading />}
      {children}
    </MainButton>
  );
};