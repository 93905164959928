import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PRIMARY_COLOR } from '../../constants';
import { Icon } from '@iconify/react';
import { useEvent } from '@cobuildlab/react-simple-state';
import { toggleButtonEvent } from './layout-events';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../routes';
import { RoleAuthorization } from '../../../modules/rbca/rbca-validators';
import { PermissionRbac } from '../../../modules/rbca/permissions';
import { useAuth0 } from '@auth0/auth0-react';

const SidebarPrincipal = styled.div.attrs(() => ({
  open: false,
}))`
  width: 225px;
  border-right: 1px solid rgba(50, 50, 50, 0.3);
  height: 100vh;
  //transition: all 600ms ease-in-out;
  transition: margin-left 600ms ease-in-out;
  margin-left: ${(props) => !props.open ? 0 : -235}px;
  position: fixed;
  background: transparent linear-gradient(180deg, #00165A 0%, #004293 100%) 0% 0% no-repeat padding-box;
  
`;

const ImgLogo = styled.img`
  max-height: 41px;
  width: 100%;
`;

const MenuList = styled.ul`
  & > li {
    margin-bottom: 1rem;
    border: 1px solid transparent;
    
  }

  & > li > a {
    color: white!important;
  }
`;

const MenuIcon = styled.img`
  margin-right: .75rem;
  position: relative;
  top: -2px;
`;

const MenuIconLogout = styled(Icon)`
  margin-right: .75rem;
  position: relative;
  top: -2px;
`;

/**
 *
 *
 * @returns {JSX.Element} Children element.
 */
export const Sidebar: React.FC = () => {
  const { logout } = useAuth0();
  const { isOpen } = useEvent(toggleButtonEvent);
  const navigate = useNavigate();
  return (
    <>
      <SidebarPrincipal className='d-flex flex-column flex-shrink-0 p-3 bg-light'
                        style={{ marginLeft: isOpen ? 0 : -235 }}>
        <a href='/' className='d-flex align-items-center text-white text-decoration-none'>
          <ImgLogo src='https://ominis.org/wp-content/uploads/2022/02/logotipo-e1644479119218.png' />
        </a>
        <hr />
        <MenuList className='nav nav-pills flex-column mb-auto'>
          {
            routes.map((route, index) => (
              <RoleAuthorization
                render={() => (
                  <li>
                    <a onClick={() => navigate(route.path)} className='nav-link' role='button' tabIndex={index}>
                      <MenuIcon
                        src={route.icon}
                        width={20}
                        height={20}
                      />
                      {route.name}
                    </a>
                  </li>
                )}
                permission={route.permission as PermissionRbac}
                error={() => null}
              />

            ))
          }
          <li>
            <a onClick={() => logout({
              returnTo: window.location.origin,
            })} className='nav-link' role='button' tabIndex={-1}>
              <MenuIconLogout
                icon='ant-design:logout-outlined'
                width={20}
                height={20}
              />
              Salir
            </a>
          </li>
        </MenuList>
      </SidebarPrincipal>
    </>
  );
};