import React from 'react';
import { openProjectObservationDialogEvent } from '../researcher-events';
import { useEvent } from '@cobuildlab/react-simple-state';
import { ModalDefault } from '../../../shared/components/Modal/ModalDefault';
import { openProjectObservationDialog } from '../researcher-actions';

export const ProjectObservationDialog: React.FC = () => {

  const { isOpen, data } = useEvent(openProjectObservationDialogEvent);

  return (
    <ModalDefault
      show={isOpen} handleClose={() => openProjectObservationDialog(false, undefined)}
      title='Observaciones'
      textClose='Cerrar'
      showOkButton={false}
    >
      <div className='text-center'>
        {data?.projectObservations ?? 'Este proyecto no tiene observaciones'}
      </div>
    </ModalDefault>
  );
};