import React, { useRef } from 'react';
import { Form } from 'react-bootstrap';
import { CardDefault } from '../../shared/components/Card/CardDefault';
import Avatar from 'react-avatar';
import { ButtonDefault } from '../../shared/components/Buttons/ButtonDefault';
import { Controller, useForm } from 'react-hook-form';
import { useCallAction, useFetchAction } from '@cobuildlab/react-simple-state';
import { fetchProfile, updateProfile } from './profile-actions';
import { showToast } from '../../shared/components/toast/toast';
import { MainLoader } from '../../shared/components/MainLoader';

export const Profile: React.FC = () => {
  const { handleSubmit, control, register, setValue } = useForm();
  const [avatarUrl, setAvatarUrl] = React.useState('');

  const [{ user }, isProfileLoading, { refetch }] = useFetchAction(fetchProfile, [],{
    onCompleted:({user:userData}) => {
      if (userData && userData?.avatar) {
        setAvatarUrl(userData?.avatar);
      }
    }
  });
  const fileRef: any = useRef();


  const [updateProfileCallback, loading] = useCallAction(updateProfile, {
    onCompleted: () => {
      showToast('success', 'Perfil actualizado correctamente!!');
      refetch();
    },
    onError: (error) => {
      showToast('error', 'Error al actualizar perfil!!');
    },
  });

  React.useEffect(() => {
    register('avatar');
  }, [register]);

  return !isProfileLoading ? (
    <form onSubmit={handleSubmit((values) => {
      updateProfileCallback({ user_id: user?.ID, ...values });
    })}>
      <div className='row'>
        <div className='col-md-8'>
          <CardDefault title='Perfil' footer={
            <div className='text-end'>
              <ButtonDefault className='text-uppercase px-4 py-2' type='submit' isLoading={loading}>
                GUARDAR
              </ButtonDefault>
            </div>
          }>
            <div className='row g-3 py-3'>
              <div className='col-md-6'>

                <Controller
                  name='firstname'
                  control={control}
                  defaultValue={user?.firstname}
                  render={
                    ({
                       field: { onChange, value },
                     }) => (
                      <>
                        <Form.Group>
                          <Form.Label>Nombre</Form.Label>
                          <Form.Control value={value} onChange={onChange} />
                        </Form.Group>
                      </>
                    )}
                />

              </div>
              <div className='col-md-6'>
                <Controller
                  name='lastname'
                  control={control}
                  defaultValue={user?.lastname}
                  render={
                    ({
                       field: { onChange, value },
                     }) => (
                      <>
                        <Form.Group>
                          <Form.Label>Apellido</Form.Label>
                          <Form.Control value={value} onChange={onChange} />
                        </Form.Group>
                      </>
                    )}
                />
              </div>
              <div className='col-md-6'>
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control placeholder='Email' readOnly value={user?.user_email} />
                </Form.Group>
              </div>
              <div className='col-md-6'>
                <Form.Group>
                  <Form.Label>Rol</Form.Label>
                  <Form.Control placeholder='Rol' readOnly value={user?.role?.replace('_', ' ')} />
                </Form.Group>
              </div>
            </div>
          </CardDefault>
        </div>
        <div className='col-md-4'>
          <div className='text-center'>
            <CardDefault>
              <>
                <Avatar
                  name={user?.display_name}
                  round='50%'
                  src={avatarUrl} maxInitials={1}
                  onClick={() => fileRef.current.click()}
                  style={{ cursor: 'pointer' }}
                />
                <input
                  hidden
                  type='file'
                  name='userprofile.avatar'
                  ref={fileRef}
                  onChange={(e) => {
                    if (e.target.files && e.target.files.length > 0) {
                      const file = e.target.files[0];
                      if (file.size >= 1000000) {
                        e.target.value = '';
                      } else {
                        setValue('avatar', file);
                        setAvatarUrl(URL.createObjectURL(file));
                      }
                    }
                  }}
                />
                <div className='mt-2'>{user?.display_name}</div>
                <div className='mt-2 fs-6 fw-bold text-capitalize'>{user?.role?.replace('_', ' ')}</div>
              </>

            </CardDefault>

          </div>
        </div>
      </div>
    </form>
  ) : <MainLoader height='80vh'>Cargando perfil</MainLoader>;
};