import React from 'react';
import { Badge, BadgeProps } from 'react-bootstrap';
import { SystemStatus } from '../../constants';
import IconPending from '../../assets/img/icon-pending.svg';
import IconAccepted from '../../assets/img/icon-approved.svg';
import IconRejected from '../../assets/img/icon-rejected.svg';


interface BadgeDefaultProps extends BadgeProps {
  status?: SystemStatus
}

export const BadgeDefault: React.FC<BadgeDefaultProps> = (
  {
    status,
    ...rest
  },
) => {

  let content = <></>;

  switch (status) {
    case SystemStatus.REJECTED:
      content = <img src={IconRejected} alt='Icon Rejected'/>;
      break;
    case SystemStatus.APPROVED:
      content = <img src={IconAccepted} alt='Icon Accepted'/>;
      break;
    default:
      content = <img src={IconPending} alt='Icon Pending'/>;
      break;
  }

  return content;
};