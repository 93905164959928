import React, { useEffect, useState } from 'react';
import { TableDefault } from '../../shared/components/Table/TableDefault';
import { BadgeDefault } from '../../shared/components/Badge/BadgeDefault';
import { useNavigate } from 'react-router-dom';
import { useEvent, useFetchAction } from '@cobuildlab/react-simple-state';
import { fetchResearchers } from './researcher-actions';
import { ResearcherType } from './researcher-types';
import { SystemStatus } from '../../shared/constants';
import { PaginateAction } from '../../shared/components/paginate/paginate-types';
import { currentEventEvent } from '../events/event-events';
import { TableLegendStatus } from '../../shared/components/Table/TableLegendStatus';
import { useSession } from '../auth/auth-hooks';

/**
 *
 @returns {JSX.Element} - Layout component of the app.
 */
export const ResearchersView: React.FC = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<PaginateAction>({
    page: 1,
    pageSize: 10,
  });
  const [data, setData] = useState<ResearcherType[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const { event } = useEvent(currentEventEvent);
  const { user } = useSession();
  const [columnsCustom, setColumnsCustom] = useState<{
    value: string;
    label: string
  }[]>([]);


  useEffect(() => {
    if (user && user.user_events && user.user_events.length) {
      const userEvents = user.user_events.find((userEvent) => userEvent.id === event?.id);
      const fields = userEvents?.fields && userEvents?.fields.length ? userEvents?.fields : [];
      setColumnsCustom(fields.filter((field) => field.type === 'file'));
    }
  }, [user, event]);

  useEffect(() => {
    if (event && event?.id) {
      setData([]);
      setTotalData(0);
    }
  }, [event]);

  const [, isLoading] = useFetchAction(
    fetchResearchers,
    [pagination.page, pagination.pageSize, event?.id as string], {
      onCompleted: ({ researchers, totalResearchers }) => {
        setData(researchers);
        setTotalData(totalResearchers);
      },
    },
  );

  return (
    <>
      <TableDefault
        title='Investigadores'
        items={data}
        isLoading={isLoading}
        pagination={pagination}
        changePage={(page) => setPagination({
          ...pagination,
          page,
        })}
        columns={[
          {
            columnName: 'Nombre completo',
            columnValue: (item: ResearcherType) => {
              if (item.data.fullname && item.data.fullname.first && item.data.fullname.last) {
                return `${item.data.fullname.first} ${item.data.fullname.last}`;
              }
              return item?.user.display_name;
            },
          },
          {
            columnName: 'Titulo',
            columnValue: (item: ResearcherType) => item?.data.projectTitle ?? '-',
          },
          ...columnsCustom.map((column) => ({
            columnName: column.label,
            columnValue: null,
            columnComponent: (item: ResearcherType) => {
              const status = item.data?.statuses?.find((s) => s.field_key === column.value);
              return (<BadgeDefault
                status={status?.value || SystemStatus.IN_PROGRESS} />);
            },
          })),
        ]}
        totalItems={totalData}
        actions={[
          {
            actionName: 'Detalles',
            onClick: (item: ResearcherType) => navigate(`/researchers/${item.ID}/details`),
          },
        ]}
        legend={<TableLegendStatus />}

      />

    </>
  );
};