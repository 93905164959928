import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CardDefault } from '../../shared/components/Card/CardDefault';
import { Form } from 'react-bootstrap';
import { SYSTEM_STATUS, SystemStatus } from '../../shared/constants';
import { ModalDefault } from '../../shared/components/Modal/ModalDefault';
import { useEvent, useFetchAction } from '@cobuildlab/react-simple-state';
import { fetchAssignedProject } from './researcher-actions';
import { MainLoader } from '../../shared/components/MainLoader';
import { ScoreForm } from './components/ScoreForm';
import { currentEventEvent } from '../events/event-events';
import { ResearcherDataType, ResearcherType } from './researcher-types';
import { showToast } from '../../shared/components/toast/toast';
import { useSession } from '../auth/auth-hooks';


export const ResearcherScoreDetailsView: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [showDocumentsStatusModal, setShowDocumentsStatusModal] = useState(false);
    const [showCardStatusModal, setShowCardStatusModal] = useState(false);
    const [rejectedCardReason, setRejectedCardReason] = useState(false);
    const [rejectedDocumentReason, setRejectedDocumentReason] = useState(false);
    const { event } = useEvent(currentEventEvent);
    const [researcher, setResearcher] = useState<ResearcherType | undefined>(undefined);
    const { user } = useSession();
    const [customField, setCustomField] = useState<{
      value: string;
      label: string;
      validation: {
        min: number;
        max: number;
      }
    }[]>([]);

    useEffect(() => {
      if (event && event?.id) setResearcher(undefined);
    }, [event]);

    useEffect(() => {
      if (user && user.user_events && user.user_events.length) {
        const userEvents = user.user_events.find((userEvent) => userEvent.id === event?.id);
        const fields = userEvents?.fields && userEvents?.fields.length ? userEvents?.fields : [];
        setCustomField(fields.filter((field) => field.type === 'scale'));
      }

    }, [user, event]);

    const [, loading, { refetch }] = useFetchAction(fetchAssignedProject, [id as string, event?.id as string, user?.ID as string], {
      onCompleted: ({ researcher: researcherData }) => {
        const data: ResearcherDataType = researcherData.data;

        if (data.is_finished) {
          showToast('error', 'Ya este proyecto fue evaluado.');
          return navigate('/researchers-score');
        }

        if (!data) {
          showToast('error', 'Esta entrega no pertenece a este concurso.');
          return navigate('/researchers-score');
        }
        setResearcher(researcherData);
      },
    });


    return (
      <>
        <CardDefault
          title='Evaluacion de proyecto'
        >
          {
            !loading ? (
              <ScoreForm researcher={researcher} onSuccess={() => {
                setResearcher(undefined);
                refetch();
              }} fields={customField} />

            ) : <MainLoader height='60vh'>Cargando investigador</MainLoader>
          }
        </CardDefault>
        <ModalDefault
          show={showCardStatusModal} handleClose={() => setShowCardStatusModal(false)}
          title='Estatus carta de aprobación'
          textClose='Cerrar'
          textSave='Actualizar'
        >
          <>
            <Form.Group className='mb-3'>
              <Form.Label>Estado</Form.Label>
              <Form.Select onChange={(e) => {
                const statusIsRejected = e.target.value === SystemStatus.REJECTED;

                if (statusIsRejected) setRejectedCardReason(true);
                if (rejectedCardReason && !statusIsRejected) setRejectedCardReason(false);

              }}>
                {
                  SYSTEM_STATUS.map((status) => (
                    <option value={status.value}>{status.label}</option>
                  ))
                }
              </Form.Select>
            </Form.Group>
            {
              rejectedCardReason && (
                <Form.Group className='mb-3'>
                  <Form.Label>Razon</Form.Label>
                  <Form.Control as='textarea' rows={5} />
                </Form.Group>
              )
            }

          </>
        </ModalDefault>
        <ModalDefault
          show={showDocumentsStatusModal} handleClose={() => setShowDocumentsStatusModal(false)}
          title='Estatus de documentos'
          textClose='Cerrar'
          textSave='Actualizar'
        >
          <>
            <Form.Group className='mb-3'>
              <Form.Label>Estado</Form.Label>
              <Form.Select onChange={(e) => {
                const statusIsRejected = e.target.value === SystemStatus.REJECTED;

                if (statusIsRejected) setRejectedDocumentReason(true);
                if (rejectedCardReason && !statusIsRejected) setRejectedDocumentReason(false);

              }}>
                {
                  SYSTEM_STATUS.map((status) => (
                    <option value={status.value}>{status.label}</option>
                  ))
                }
              </Form.Select>
            </Form.Group>
            {
              rejectedDocumentReason && (
                <Form.Group className='mb-3'>
                  <Form.Label>Razon</Form.Label>
                  <Form.Control as='textarea' rows={5} />
                </Form.Group>
              )
            }

          </>
        </ModalDefault>
      </>
    );
  }
;