import { createEvent } from '@cobuildlab/react-simple-state';
import { ChangeStatusType, ResearcherDataType, ResearcherType } from './researcher-types';
import { UserType } from '../users/user-types';

export const fetchResearchersEvent = createEvent<{ researchers: ResearcherType[], totalResearchers: number }>({
  initialValue: {
    researchers: [],
    totalResearchers: 0,
  },
});

export const fetchResearchersErrorEvent = createEvent<Error>();

export const fetchResearcherEvent = createEvent<{researcher:ResearcherType | null}>({
  initialValue: {
    researcher: {
      ID:'',
      data:{
        files:[]
      },
      user:{
        ID:''
      }
    },
  },
});

export const fetchResearcherErrorEvent = createEvent<Error>();

export const fetchJudgesEvent = createEvent<{ judges: UserType[], totalUsers: number }>({
  initialValue: {
    judges: [],
    totalUsers: 0,
  },
});
export const fetchJudgesErrorEvent = createEvent<Error>();

export const openAssignJudgesDialogEvent = createEvent<{ isOpen: boolean; judges: UserType[] }>({
  initialValue: {
    isOpen: false,
    judges: [],
  },
});

export const openChangeStatusDialogEvent = createEvent<{ isOpen: boolean, data: { [key: string]: string | number | object }[] | [] }>({
  initialValue: {
    isOpen: false,
    data: [],
  },
});

export const openProjectObservationDialogEvent = createEvent<{ isOpen: boolean, data: ResearcherDataType | undefined }>(
  {
    initialValue: {
      isOpen: false,
      data: undefined,
    },
  },
);

export const changeStatusEvent = createEvent();
export const changeStatusErrorEvent = createEvent<Error>();

export const assignJudgeEvent = createEvent();
export const assignJudgeErrorEvent = createEvent<Error>();

export const fetchAssignedProjectsEvent = createEvent<{ researchers: ResearcherType[], totalResearchers: number }>({
  initialValue: {
    researchers: [],
    totalResearchers: 0,
  },
});

export const fetchAssignedProjectsErrorEvent = createEvent<Error>();

export const fetchAllAssignedProjectsEvent = createEvent<{ researchers: ResearcherType[], totalResearchers: number }>({
  initialValue: {
    researchers: [],
    totalResearchers: 0,
  },
});

export const fetchAllAssignedProjectsErrorEvent = createEvent<Error>();

export const fetchAssignedProjectEvent = createEvent<{ researcher: ResearcherType | undefined }>({
  initialValue: {
    researcher: undefined,
  },
});

export const fetchAssignedProjectErrorEvent = createEvent<Error>();

export const updateScoreEvent = createEvent();
export const updateScoreErrorEvent = createEvent<Error>();