import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { ResearcherType, ScoreUpdateInput } from '../researcher-types';
import validator from 'validator';
import { ButtonDefault } from '../../../shared/components/Buttons/ButtonDefault';
import { useCallAction } from '@cobuildlab/react-simple-state';
import { updateScore } from '../researcher-actions';
import { showToast } from '../../../shared/components/toast/toast';
import { useNavigate, useParams } from 'react-router-dom';
import { useSession } from '../../auth/auth-hooks';

type ScoreFormProps = {
  researcher: ResearcherType | undefined;
  onSuccess: () => void;
  fields: {
    value: string;
    label: string;
    validation:{
      min:number;
      max:number;
    }
  }[]
}

export const ScoreForm: React.FC<ScoreFormProps> = ({ researcher, onSuccess, fields }) => {

  const { handleSubmit, control, reset } = useForm();
  const { id } = useParams<{ id: string }>();
  const { user } = useSession();
  const navigate = useNavigate();
  const [updateScoreCallback, loading] = useCallAction(updateScore, {
    onCompleted: () => {
      reset();
      onSuccess();
      showToast('success', 'Se actualizo la calificacion correctamente');
      return navigate('/researchers-score');

    },
    onError: (error) => {
      showToast('error', error.message);
    },
  });

  if (!researcher) return null;

  return (
    <form onSubmit={handleSubmit((values: ScoreUpdateInput) => {
      updateScoreCallback({ data: values, researcher_id: id as string, user_id: user?.ID as string });
    })}>

      <div className='row'>
        <div className='col-3'>
          <Form.Group className='mb-3'>
            <Form.Label>ID</Form.Label>
            <Form.Control readOnly value={researcher.ID} />
          </Form.Group>
          <Form.Group className='mb-3'>
            <Form.Label>Título</Form.Label>
            <Form.Control readOnly value={researcher.data.projectTitle} />
          </Form.Group>


          {
            fields.map((field) => {
              return (
                <Form.Group className='mb-3'>
                  <Controller
                    name={field.value}
                    control={control}
                    defaultValue={researcher.data.evaluation?.[field.value as never]}
                    rules={{
                      validate: (value) => {
                        console.log('value', value);
                        const number = parseInt(value);
                        if (value && !validator.isNumeric(value)) {
                          return 'Ingrese un numero valido';
                        }
                        if (!(number >= field.validation.min && number <= field.validation.max)) {
                          return `El numero ingresado debe estar entre ${field.validation.min} y ${field.validation.max}`;
                        }
                        return true;
                      },
                    }}
                    render={
                      ({
                         field: { onChange, value },
                         fieldState: { invalid, error },
                       }) => (
                        <>
                          <Form.Label>{field.label}</Form.Label>
                          <Form.Control value={value} onChange={onChange} />
                          {
                            invalid && (
                              <Form.Text className='text-danger'>
                                {error?.message}
                              </Form.Text>
                            )
                          }
                        </>
                      )}
                  />

                </Form.Group>
              );
            })
          }

          <Form.Group className='mb-3'>
            <Controller
              name='observations'
              control={control}
              defaultValue={researcher.data.evaluation?.['observations' as never]}
              render={
                ({
                   field: { onChange, value },
                 }) => (
                  <>
                    <Form.Label>Observaciones</Form.Label>
                    <Form.Control value={value} onChange={onChange} as='textarea' rows={4} />
                  </>
                )}
            />
          </Form.Group>

          <Form.Group className='mb-3'>
            <ButtonDefault
              className='text-uppercase px-4 py-2'
              type='submit'
              style={{ width: '100%' }}
              isLoading={loading}
            >
              Calificar
            </ButtonDefault>
          </Form.Group>
        </div>
        <div className='col-9 text-center'>
          <>
            <iframe
              title='Tésis'
              src={`${researcher.data.projectFile}`}
              frameBorder='0'
              style={{
                display: 'block',
                width: '100%',
                height: '900px',
                border: 'none',
              }}
            />
          </>
        </div>
      </div>
    </form>
  );
};