import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useLocation } from 'react-router-dom';
import { MainLoader } from '../../shared/components/MainLoader';

// TODO add loading component
/**
 * @returns {JSX.Element} - Auth component.
 */
export function Auth(): JSX.Element {
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();

  console.log(location.state);

  useEffect(() => {
    loginWithRedirect(
      {
        appState: location.state,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <MainLoader/>;
}