import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CardDefault } from '../../shared/components/Card/CardDefault';
import { Col, Form, Row, Badge } from 'react-bootstrap';
import { ButtonDefault } from '../../shared/components/Buttons/ButtonDefault';
import { useEvent, useFetchAction } from '@cobuildlab/react-simple-state';
import { fetchResearcher, openAssignJudgesDialog, openChangeStatusDialog } from './researcher-actions';
import { MainLoader } from '../../shared/components/MainLoader';
import { ChangeStatusType, FileType, ResearcherDataType } from './researcher-types';
import { SelectStatus } from '../../shared/components/Select/SelectStatus';
import { AssignJudgesDialog } from './components/AssignJudgesDialog';
import { ChangeStatusDialog } from './components/ChangeStatusDialog';
import { SystemStatus } from '../../shared/constants';
import { currentEventEvent } from '../events/event-events';
import { showToast } from '../../shared/components/toast/toast';
import { TableButtonDefault } from '../../shared/components/Table/TableButtonDefault';
import { UserType } from '../users/user-types';

export const ResearcherDetailsView: React.FC = () => {
    const navigate = useNavigate();
    const { id } = useParams<{ id: string }>();
    const [documentSelected, setDocumentSelected] = useState<FileType>();
    const [name, setName] = useState<string>('');
    const { event } = useEvent(currentEventEvent);
    const [isApproved, setIsApproved] = useState<boolean>(false);
    const [statuses, setStatuses] = useState<{ [key: string]: string | number | object }[]>([]);

    const [{ researcher }, loading, { refetch }] = useFetchAction(fetchResearcher, [id as string, event?.id as string], {
      onCompleted: ({ researcher: researcherData }) => {
        const data: ResearcherDataType = researcherData.data;
        console.log('data', data);
        if (!data) {
          showToast('error', 'Esta entrega no pertenece a este concurso!!');
          return navigate('/researchers');
        }

        if (researcherData.data.fullname &&
          researcherData.data.fullname.first &&
          researcherData.data.fullname.last
        ) {
          setName(`${researcherData.data.fullname.first} ${researcherData.data.fullname.last}`);
        } else {
          setName(`${researcherData?.user.display_name}`);
        }

        const statusesData = data.statuses?.filter((status) => status.value === SystemStatus.APPROVED);
        setDocumentSelected(data.files?.length ? data.files[0] : {} as FileType);
        setIsApproved(statusesData?.length === (data.statuses || []).length);
        setStatuses(data.statuses || []);
      },
    });

    console.log('researcher?.data', researcher?.data);

    return (
      <>
        <CardDefault
          title='Proyecto'
          cardActions={
            !loading ?

              [

                <Form.Group as={Row} className='text-center mx-auto' style={{ width: 400 }}>
                  <Form.Label column sm={4}>Documentos</Form.Label>
                  <Col sm={8}>
                    <Form.Select onChange={(x) => {
                      setDocumentSelected((researcher?.data ? researcher?.data.files : [])?.find((file: FileType) => file.field_key === x.target.value as string));
                    }}>
                      {
                        (researcher?.data ? researcher?.data.files : [])?.map((file: FileType) => (
                          <option value={file.field_key}>{file.label}</option>
                        ))
                      }
                    </Form.Select>
                  </Col>

                </Form.Group>,
                isApproved ?
                  (
                    <TableButtonDefault
                      onClick={() => openAssignJudgesDialog(true, researcher.data.judges)}
                    >
                      Asignar juez
                    </TableButtonDefault>
                  ) : <></>,
              ] : undefined
          }
        >
          {
            !loading ? (
              <div className='row'>
                <div className='col-3'>
                  <Form.Group className='mb-3'>
                    <Form.Label>Investigador responsable</Form.Label>
                    <Form.Control readOnly value={name} />
                  </Form.Group>
                  {
                    researcher &&
                    researcher.user &&
                    researcher.user.user_email && (
                      <Form.Group className='mb-3'>
                        <Form.Label>Correo</Form.Label>
                        <Form.Control readOnly value={researcher.user.user_email} />
                      </Form.Group>
                    )
                  }
                  {
                    researcher &&
                    researcher.data &&
                    researcher.data.judges &&
                    researcher.data.judges.length &&
                    isApproved ?
                      (
                        <>
                          <Form.Label>Jueces Asignados</Form.Label>
                          <Form.Group className='mb-3 '>

                            {
                              researcher.data.judges.map((judge: UserType) => (
                                <Badge pill bg='dark' style={{ marginRight: '.5rem', fontSize: '.75rem' }}
                                       className='mb-1'>
                                  {judge.display_name || judge.user_email}
                                </Badge>
                              ))
                            }
                          </Form.Group>
                        </>
                      ) : undefined
                  }
                  {
                    statuses.map((status, i) => (
                      <Form.Group className='mb-3'>
                        <Form.Label>{`${status.label}:`}</Form.Label>
                        <SelectStatus
                          status={status.value as SystemStatus}
                          onChange={(v) => {
                            const newStatuses = statuses;
                            newStatuses[i].value = v;
                            setStatuses([...newStatuses]);
                          }} />
                      </Form.Group>
                    ))
                  }

                  <Form.Group className='mb-3'>
                    <ButtonDefault
                      className='text-uppercase px-4 py-2'
                      style={{ width: '100%' }}
                      onClick={() => openChangeStatusDialog(true, statuses)}
                    >Actualizar estatus</ButtonDefault>
                  </Form.Group>
                </div>
                <div className='col-9 text-center'>
                  <>
                    <iframe
                      title={documentSelected?.label}
                      src={`${documentSelected?.value}`}
                      frameBorder='0'
                      style={{
                        display: 'block',
                        width: '100%',
                        height: '700px',
                        border: 'none',
                      }}
                    />
                  </>
                </div>
              </div>

            ) : <MainLoader height='60vh'>Cargando investigador</MainLoader>
          }
        </CardDefault>
        <AssignJudgesDialog onSuccess={() => refetch()} />
        <ChangeStatusDialog onSuccess={() => refetch()} />
      </>
    );
  }
;