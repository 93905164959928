import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEvent, useFetchAction } from '@cobuildlab/react-simple-state';
import { fetchAssignedProjects, openProjectObservationDialog } from './researcher-actions';
import { TableDefault } from '../../shared/components/Table/TableDefault';
import { ResearcherType } from './researcher-types';
import { useSession } from '../auth/auth-hooks';
import { ProjectObservationDialog } from './components/ProjectObservationDialog';
import { PaginateAction } from '../../shared/components/paginate/paginate-types';
import { currentEventEvent } from '../events/event-events';
import { ButtonDefault } from '../../shared/components/Buttons/ButtonDefault';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import ImgApproved from '../../shared/assets/img/icon-approved.svg';


export const ResearchersScoreView: React.FC = () => {
    const navigate = useNavigate();
    const { user } = useSession();
    const [pagination, setPagination] = useState<PaginateAction>({
      page: 1,
      pageSize: 10,
    });
    const { event } = useEvent(currentEventEvent);
    const [columnsCustom, setColumnsCustom] = useState<{
      value: string;
      label: string
    }[]>([]);
    const [{
      researchers,
      totalResearchers,
    }, isLoading, { refetch }] = useFetchAction(fetchAssignedProjects, [user?.ID as string, pagination.page, pagination.pageSize, event?.id as string]);

    useEffect(() => {
      if (user && user.user_events && user.user_events.length) {
        const userEvents = user.user_events.find((userEvent) => userEvent.id === event?.id);
        const fields = userEvents?.fields && userEvents?.fields.length ? userEvents?.fields : [];
        setColumnsCustom(fields);
      }

    }, [user, event]);

    return (
      <>
        <TableDefault
          title='Proyectos'
          items={researchers}
          isLoading={isLoading}
          pagination={pagination}
          changePage={(page) => setPagination({
            ...pagination,
            page,
          })}
          columns={[
            {
              columnName: 'ID',
              columnValue: (item: ResearcherType) => item?.ID,
            },
            {
              columnName: 'Título',
              columnValue: (item: ResearcherType) => item?.data.projectTitle,
            },

            {
              columnName: 'Descripción',
              columnValue: (item: ResearcherType) => item?.data.projectDescription,
            },
            {
              columnName: 'Total',
              columnValue: (item: ResearcherType) => item?.data.total,
            },
          ]}
          totalItems={totalResearchers}
          actions={[
            // {
            //   actionName: 'Evaluar',
            //   onClick: (item) => navigate(`/researcher-score/${item.user.ID}/details`),
            // },
            // {
            //   actionName: 'Observaciones',
            //   onClick: (item) => openProjectObservationDialog(true, item.data),
            // },
          ]}
          actionComponent={(item: ResearcherType) => !item?.data.is_finished ? (
            <div style={{ margin: '0 1rem' }}>
              <ButtonDefault className='text-uppercase px-4 py-2'
                             onClick={() => navigate(`/researcher-score/${item.ID}/details`)}>Evaluar
              </ButtonDefault>
            </div>
          ) : (
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">Evaluado</Tooltip>} >
              <img src={ImgApproved} alt='icon approved'/>
            </OverlayTrigger>
          )
          }
        />
        <ProjectObservationDialog />
      </>
    );
  }
;