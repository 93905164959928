import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import {
  AUTH0_CLIENT_ID, AUTH0_DOMAIN,
} from '../../shared/constants';

export type AppState = {
  returnTo?: string;
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
};

/**
 * @param {object} props -  Props.
 * @param {JSX.Element} props.children -  Props.
 * @returns {JSX.Element} Auth0ProviderWithHistory.
 */
export function Auth0ProviderWithHistory(
  {
    children,
  }: {
    children: React.ReactNode;
  }): JSX.Element {
  const navigate = useNavigate();

  /**
   * @param appState - State of the app.
   */
  const onRedirectCallback = (appState: AppState): void => {
    navigate(appState?.returnTo || window.location.pathname);
  };
  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
    >
      {children}
    </Auth0Provider>
  );
}