import { createEvent } from '@cobuildlab/react-simple-state';
import { UserType } from '../users/user-types';


export const updateProfileEvent = createEvent();

export const updateProfileErrorEvent = createEvent<Error>();

export const fetchProfileEvent = createEvent<{user: UserType | undefined}>({
  initialValue:{
    user: undefined
  }
});

export const fetchProfileErrorEvent = createEvent<Error>();