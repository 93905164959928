/**
 * Authenticate or Signup 8base user.
 *
 * @param {string} email  - The email of the User authenticated.
 */
import { createAction } from '@cobuildlab/react-simple-state/lib/actions';
import { OnFetchSessionErrorEvent, OnFetchSessionEvent, OnTokenEvent } from './auth-events';
import { API_ENDPOINT } from '../../shared/constants';


export const handleAuthentication = async (email: string): Promise<void> => {

  // TODO: get login with wp

  console.log('email', email);

  // try {
  //   await client.query({
  //     query: CURRENT_USER_QUERY,
  //   });
  // } catch (error) {
  //   await client.mutate({
  //     mutation: USER_SIGN_UP_MUTATION,
  //     variables: {
  //       user: { email },
  //       authProfileID: AUTH_PROFILE_ID,
  //     },
  //   });
  // }
};


export const fetchSession = createAction(
  OnFetchSessionEvent,
  OnFetchSessionErrorEvent, async () => {
    const response = await fetch(`${API_ENDPOINT}/wp-json/wp/v2/tainacan/user/info`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${OnTokenEvent.get()?.token as string}`,
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();

    if (!response.ok) {
      throw Error('No se puede obtener la informacion del usaurio');
    }
    return { user: json.user };
  },
);