import React, { PropsWithChildren } from 'react';
import { Table, TableProps } from 'react-bootstrap';
import { IDObj, IdObj, MenuActionType, TableColumnType } from './table-types';
import styled, { keyframes } from 'styled-components';
import { MainLoader } from '../MainLoader';
import { TableMenuAction } from './TableMenuAction';
import { Pagination } from '../paginate/Pagination';
import { PaginateAction } from '../paginate/paginate-types';
import { TableCardDefault } from './TableCardDefault';



const TableDesign = styled(Table)`
  & {
    margin-bottom: 0 !important;
  }

  & > tbody > tr, & > thead > tr {
    vertical-align: middle;
    text-align: center;
    font-size: 16px
  }

  & > thead {
    background: #E3E3E3 0% 0% no-repeat padding-box;
    height: 60px;
  }

  & > tbody {
    border-top: none !important;
  }
`;


export interface TableDefaultProps<T> extends TableProps {
  items: T[];
  columns: TableColumnType<T>[];
  actions?: MenuActionType<T>[];
  totalItems: number;
  isLoading?: boolean;
  messageLoading?: string;
  title?: string;
  cardActions?: React.ReactElement[];
  pagination: PaginateAction;
  changePage: (page: number) => void;
  actionComponent?: (item: T, index: number) => React.ReactElement | React.ReactElement[];
  legend?: React.ReactElement;
}

export const TableDefault = <T extends IdObj | IDObj>(
  props: PropsWithChildren<TableDefaultProps<T>>,
): JSX.Element => {

  const {
    columns,
    actions,
    totalItems,
    items,
    isLoading,
    messageLoading,
    cardActions,
    title,
    pagination,
    changePage,
    actionComponent,
    legend,
    ...rest
  } = props;

  let totalColumns = columns.length;

  const tableHead = columns.map((column) => (
    <th>{column.columnName}</th>
  ));

  if (actions && actions.length || actionComponent) {
    totalColumns += 1;
    tableHead.push(<th>Acciones</th>);
  }


  const tableBody = isLoading ? (
    <tr>
      <td colSpan={totalColumns}>
        <MainLoader height='65vh'>
          {messageLoading}
        </MainLoader>
      </td>
    </tr>

  ) : (
    <>
      {
        items.map((item, i) => {

          const index = i + 1;

          const columnData = columns.map((column) => {
            if (column.columnValue) {
              return (
                <td>
                  {column.columnValue(item, index)}
                </td>
              );
            }

            if (column.columnComponent) {
              return (
                <td>
                  {column.columnComponent(item, index)}
                </td>
              );
            }

            if (column.columnComponentClean) {
              return column.columnComponentClean(item, index);
            }
            return null;
          });

          if (actions && actions.length) {
            columnData.push(
              <td>
                <TableMenuAction actions={actions} item={item} />
              </td>,
            );
          }

          if (actionComponent) {
            columnData.push(
              <td>
                {actionComponent(item, index)}
              </td>,
            );
          }

          const bgRow = i % 2 !== 0 ? {
            background: '#F8F8F8 0% 0% no-repeat padding-box',
          } : {};

          return (
            <tr style={bgRow}>
              {columnData}
            </tr>
          );

        })
      }
    </>
  );


  return (
    <TableCardDefault
      title={title}
      cardActions={cardActions}
      legend={legend}
      footer={
        Math.ceil(totalItems / pagination.pageSize) > 1 ? (
          <Pagination
            pageCount={Math.ceil(totalItems / pagination.pageSize)}
            pageRangeDisplayed={pagination.pageSize}
            onPageChange={({ selected: page }) => changePage(page + 1)}
          />
        ) : null
      }


    >
      <div style={{ minHeight: '65vh' }}>
        <TableDesign {...rest}  >
          {
            !isLoading ? (
              <thead>
              <tr>
                {tableHead}
              </tr>
              </thead>
            ) : null
          }
          <tbody>
          {tableBody}
          </tbody>
        </TableDesign>
      </div>

    </TableCardDefault>
  );
};