import { PermissionRbac } from '../modules/rbca/permissions';
import IconResearcher from './assets/img/icon-researcher.svg';
import IconUsers from './assets/img/icon-users.svg';
import IconProjects from './assets/img/icon-projects.svg';
import IconEvents from './assets/img/icon-events.svg';
import IconProfile from './assets/img/icon-profile.svg';

type RotesType = {
  name: string;
  path: string,
  icon: string;
  permission?: string
}

export const routes: RotesType[] = [
  // {
  //   name: 'Inicio',
  //   path: '/dashboard',
  //   icon: 'ant-design:home-outlined',
  //   permission: PermissionRbac.DASHBOARD
  // },
  {
    name: 'Investigadores',
    path: '/researchers',
    icon: IconResearcher,
    permission: PermissionRbac.RESEARCHERS,
  },
  {
    name: 'Usuarios',
    path: '/users',
    icon: IconUsers,
    permission: PermissionRbac.USERS,
  },

  {
    name: 'Proyectos',
    path: '/researchers-score',
    icon: IconProjects,
    permission: PermissionRbac.RESEARCHERS_SCORE,
  },

  {
    name: 'Proyectos',
    path: '/all-researchers-score',
    icon: IconProjects,
    permission: PermissionRbac.ALL_RESEARCHERS_SCORE,
  },
  // {
  //   name: 'Concursos',
  //   path: '/events',
  //   icon: IconEvents,
  //   permission: PermissionRbac.EVENTS,
  //
  // },
  {
    name: 'Perfil',
    path: '/profile',
    icon: IconProfile,
    permission: PermissionRbac.PROFILE,
  },
];