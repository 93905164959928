import { RBAC as RBACClass } from '@cobuildlab/rbac';
import { PermissionRbac } from './permissions';
import { SystemRoles } from '../../shared/constants';

const RbacSchema = [
  {
    Role: SystemRoles.SUPER_ADMINISTRATOR,
    Permission: [
      PermissionRbac.RESEARCHERS,
      PermissionRbac.RESEARCHERS_DETAILS,
      PermissionRbac.USERS,
      PermissionRbac.PROFILE,
      PermissionRbac.EVENTS,
      PermissionRbac.ALL_RESEARCHERS_SCORE,
      PermissionRbac.RESEARCHERS_SCORE_DETAILS,
      PermissionRbac.DROPDOWN_EVENT
    ],
  },
  {
    Role: SystemRoles.ADMINISTRATOR,
    Permission: [
      PermissionRbac.ALL_RESEARCHERS_SCORE,
      PermissionRbac.RESEARCHERS,
      PermissionRbac.RESEARCHERS_DETAILS,
      PermissionRbac.PROFILE,
      PermissionRbac.DASHBOARD,
      PermissionRbac.DROPDOWN_EVENT
    ],
  },
  {
    Role: SystemRoles.JUDGES,
    Permission: [
      PermissionRbac.RESEARCHERS_SCORE,
      PermissionRbac.RESEARCHERS_SCORE_DETAILS,
      PermissionRbac.PROFILE,
      PermissionRbac.DROPDOWN_EVENT,
    ],
  },
];

export const RBAC = new RBACClass<SystemRoles, PermissionRbac>(SystemRoles.SUPER_ADMINISTRATOR);

RbacSchema.forEach((rule) => {
  rule.Permission.forEach((PermissionRole) =>
    RBAC.createRule(rule.Role, PermissionRole, true),
  );
});
