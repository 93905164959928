import React, { useEffect, useState } from 'react';
import { ModalDefault } from '../../../shared/components/Modal/ModalDefault';
import { Form } from 'react-bootstrap';
import { UserType } from '../../users/user-types';
import { useCallAction, useEvent, useFetchAction } from '@cobuildlab/react-simple-state';
import { fetchResearcherEvent, openAssignJudgesDialogEvent } from '../researcher-events';
import { assignJudge, fetchJudges, openAssignJudgesDialog, openChangeStatusDialog } from '../researcher-actions';
import { useParams } from 'react-router-dom';
import { showToast } from '../../../shared/components/toast/toast';
import { currentEventEvent } from '../../events/event-events';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select/base';
import { EventType } from '../../events/event-types';
import { ReactSelectOption } from '../../../shared/components/ReactSelect/ReactSelectOption';

const filterRoles = ['juez'];

type AssignJudgesDialogProps = {
  onSuccess: () => void;
}

export const AssignJudgesDialog: React.FC<AssignJudgesDialogProps> = (
  {
    onSuccess,
  }) => {

  const { isOpen, judges: selectedJudges } = useEvent(openAssignJudgesDialogEvent);
  const { researcher } = useEvent(fetchResearcherEvent);
  const { id } = useParams<{ id: string }>();
  const { event } = useEvent(currentEventEvent);
  const { control, setValue } = useForm();
  const [{ judges }] = useFetchAction(fetchJudges, [filterRoles, researcher?.data ? researcher?.data.category : '', event?.id as string]);
  const [userValues, setUserValues] = useState<{ value: number, label: string }[]>([]);
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (selectedJudges && selectedJudges.length) setUserValues(selectedJudges.map((user) => ({
      value: parseInt(user.ID as string),
      label: user.display_name as string,
    })));
  }, [selectedJudges]);

  const [AssignJudgeCallback, loading] = useCallAction(assignJudge, {
    onCompleted: () => {
      showToast('success', 'Se assigno correctamente un juez!!');
      openAssignJudgesDialog(false, []);
      setUserValues([]);
      onSuccess();
    },
    onError: (error) => {
      showToast('error', error.message);
    },
  });

  return (
    <ModalDefault
      show={isOpen} handleClose={() => openAssignJudgesDialog(false, [])}
      title='Asignar Jueces'
      textClose='Cerrar'
      textSave='Asignar'
      isLoading={loading}
      onSave={() => AssignJudgeCallback({
        users: userValues.map((userValue) => ({ id: userValue.value })),
        researcher_id: id as string,
        event_id: event?.id as string,
      })}
    >
      <>

        <Controller
          name='event'
          control={control}
          defaultValue={userValues}
          render={
            ({
               fieldState: { invalid, error },

             }) => (
              <Form.Group className='mb-4'>
                <Select
                  placeholder='Jueces'
                  isMulti
                  isLoading={false}
                  value={userValues}
                  options={judges.map((user: UserType) => ({
                    value: parseInt(user.ID as string),
                    label: user.display_name as string,
                  }))}
                  onChange={(eventData) => {
                    setValue('event', [...eventData]);
                    setUserValues([...eventData as []]);
                  }}
                  components={{
                    Option: function OptionCustom(props) {
                      return (
                        <ReactSelectOption
                          reactSelectProps={props}
                          defaultValues={userValues}
                        />
                      );
                    },
                  }}
                  inputValue=''
                  menuIsOpen={open}
                  onInputChange={() => {
                  }}
                  onMenuClose={() => setOpen(false)}
                  onMenuOpen={() => setOpen(true)}
                />
                {
                  invalid && (
                    <Form.Text className='text-danger'>
                      {error?.message}
                    </Form.Text>
                  )
                }
              </Form.Group>
            )}
        />
      </>
    </ModalDefault>
  );
};