import React, { ReactElement, useEffect, useState } from 'react';
import { PermissionRbac } from './permissions';
import { useSession } from '../auth/auth-hooks';
import { RBAC } from './rbca-permissions';
import { SystemRoles } from '../../shared/constants';
import { OnFetchSessionEvent } from '../auth/auth-events';

export const checkRoleAuthorization = (
  permission: PermissionRbac,
): [boolean, string] => {
  const user = OnFetchSessionEvent.get()?.user;
  const [canRender, message = ''] = RBAC.check(user?.role as SystemRoles, permission);
  console.log('canRender', canRender);
  if (canRender) {
    return [true, message];
  }

  return [false, message];
};

/**
 * @description This is the default component for erros.
 * @returns The default error component.
 */
export const ErrorDefaultView: React.FC = () => (
  <div>You dont have permission</div>
);

export const RoleAuthorization: React.FC<RoleAuthorizationProps> = (
  {
    render,
    error = () => <ErrorDefaultView/>,
    permission,
  }) => {
  const session = useSession();
  const [checkAuth, setCheckAuth] = useState<boolean>(false);
  const [messageAuth, setMessageAuth] = useState<string>('');

  useEffect(() => {
    const [check, message] = checkRoleAuthorization(permission);
    setCheckAuth(check);
    setMessageAuth(message);
  }, [permission, session]);

  if (checkAuth) return render(messageAuth);
  return error(messageAuth);
};

type RoleAuthorizationProps = {
  render: (
    message: string,
  ) => ReactElement<string | React.JSXElementConstructor<unknown>>; // eslint-disable-line @typescript-eslint/no-explicit-any
  error?: (
    message: string,
  ) => ReactElement<string | React.JSXElementConstructor<unknown>> | null; // eslint-disable-line @typescript-eslint/no-explicit-any
  permission: PermissionRbac;
};