import React, { useEffect, useState } from 'react';
import { Loading, ModalDefault } from '../../../shared/components/Modal/ModalDefault';
import { Form } from 'react-bootstrap';
import { PRIMARY_COLOR, ROLES, SystemRoles } from '../../../shared/constants';
import { useCallAction, useEvent, useFetchAction } from '@cobuildlab/react-simple-state';
import Select from 'react-select/base';
import { showToast } from '../../../shared/components/toast/toast';
import {
  createEventCategoryInvitation,
  createEventInvitation,
  fetchEvents,
  openInviteUserEventCategoryDialog,
  openInviteUserEventDialog,
} from '../../events/event-actions';
import { EventType } from '../../events/event-types';
import { Controller, useForm } from 'react-hook-form';
import { ButtonDefault } from '../../../shared/components/Buttons/ButtonDefault';
import { openInviteUserEventCategoryDialogEvent, openInviteUserEventDialogEvent } from '../../events/event-events';
import { ReactSelectOption } from '../../../shared/components/ReactSelect/ReactSelectOption';
import { CategoryType } from '../user-types';

type InviteUserDialogProps = {
  onSuccess: () => void;
}

export const InviteUserEventCategoryDialog: React.FC<InviteUserDialogProps> = (
  {
    onSuccess,
  }) => {
  const { handleSubmit, control, reset, setValue } = useForm();
  const { isOpen, user } = useEvent(openInviteUserEventCategoryDialogEvent);
  const [open, setOpen] = useState<boolean>(false);
  const [eventValues, setEventValues] = useState<{ value: number, label: string }[]>([]);
  const [categories, setCategories] = useState<{ value: string; label: string }[]>([]);
  const [categoryValues, setCategoryValues] = useState<{ value: number, label: string }[]>([]);

  useEffect(() => {
    if (user && user.user_events) {
      setEventValues(
        user?.user_events?.map((event: EventType) => ({
          value: parseInt(event.id as string),
          label: event.name as string,
        })),
      );
      setValue('event', user?.user_events?.map((event: EventType) => ({
        value: parseInt(event.id as string),
        label: event.name as string,
      })));
    }
  }, [user, setValue]);


  const [createInvitationSubmit, loading] = useCallAction(createEventCategoryInvitation, {
    onCompleted: () => {
      showToast('success', 'Usuario se ha invitado a las categorias correctamente!!!');
      openInviteUserEventCategoryDialog(false, undefined);
      setEventValues([]);
      setCategories([]);
      setCategoryValues([]);
      reset();
      onSuccess();
    },
    onError: (error) => {
      showToast('error', error.message);
    },
  });

  const [{ events }] = useFetchAction(fetchEvents, [1, 100]);

  return (
    <ModalDefault
      show={isOpen}
      title='Asignar Categoria'
      textClose='Cerrar'
      textSave='Invitar'
      isLoading={loading}
      showFooterButtons={false}
    >
      <>
        <form onSubmit={handleSubmit((values) => {
          createInvitationSubmit({
            user_id: user?.ID as string,
            ...values,
            categories: categoryValues
          });
        })}>

          <Controller
            name='event'
            control={control}
            defaultValue=''
            render={
              ({
                 field: { onChange, value },
                 fieldState: { invalid, error },

               }) => (
                <Form.Group className='mb-3'>
                  <Form.Select onChange={(e) => {

                    onChange(e);
                    const selectedEvent = events.find((event: EventType) => event.id === e.target.value);
                    if (selectedEvent) {
                      setCategories(selectedEvent.categories);
                      const categoriesDefault = user?.categories?.find((userCategoryDefault) => parseInt(userCategoryDefault?.form_id as string) === parseInt(selectedEvent.id));
                      if (categoriesDefault) {
                        setCategoryValues(categoriesDefault.categories.map((category: CategoryType) => ({
                          value: parseInt(category?.value as string),
                          label: category.label as string,
                        })));
                      }
                    } else {
                      setCategoryValues([]);
                      setCategories([]);
                    }
                  }} value={value} placeholder='Convocatoria'>
                    <option value=''>Seleccionar convocatoria</option>
                    {
                      eventValues.map((event) => (
                        <option value={event?.value}>{event?.label}</option>
                      ))
                    }
                  </Form.Select>
                  {
                    invalid && (
                      <Form.Text className='text-danger'>
                        {error?.message}
                      </Form.Text>
                    )
                  }
                </Form.Group>

              )}
          />
          <Controller
            name='categories'
            control={control}
            defaultValue={categoryValues}
            render={
              ({
                 field: { onChange, value },
                 fieldState: { invalid, error },

               }) => (
                <Form.Group>
                  <Select
                    placeholder='Categorias'
                    isMulti
                    isDisabled={!categories.length}
                    isLoading={false}
                    value={categoryValues}
                    options={categories.map((category: { value: string; label: string }) => ({
                      value: parseInt(category.value),
                      label: category.label as string,
                    }))}
                    onChange={(categoryData) => {
                      setCategoryValues([...categoryData as []]);
                    }}
                    components={{
                      Option: function OptionCustom(props) {
                        return (
                          <ReactSelectOption
                            reactSelectProps={props}
                            defaultValues={categoryValues}
                          />
                        );
                      },
                    }}
                    inputValue=''
                    menuIsOpen={open}
                    onInputChange={() => {
                    }}
                    onMenuClose={() => setOpen(false)}
                    onMenuOpen={() => setOpen(true)}
                  />
                  {
                    invalid && (
                      <Form.Text className='text-danger'>
                        {error?.message}
                      </Form.Text>
                    )
                  }
                </Form.Group>
              )}
          />

          <Form.Group className='mb-3 text-center mt-3'>
            <ButtonDefault
              style={{ backgroundColor: PRIMARY_COLOR, color: 'white', minWidth: 100 }}
              onClick={() => {
                setCategoryValues([]);
                setCategories([]);
                setEventValues([]);
                openInviteUserEventCategoryDialog(false, undefined);
              }}
              className='text-uppercase px-4 py-2 mx-2'
            >
              Cerrar
            </ButtonDefault>

            <ButtonDefault
              className='text-uppercase px-4 py-2'
              disabled={loading}
              type='submit'
            >
              {loading && <Loading />}
              Invitar
            </ButtonDefault>

          </Form.Group>
        </form>
      </>
    </ModalDefault>

  );
};