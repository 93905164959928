export enum PermissionRbac {
  RESEARCHERS = 'RESEARCHERS',
  RESEARCHERS_DETAILS = 'RESEARCHERS_DETAILS',
  USERS = 'USERS',
  EVENTS = 'EVENTS',
  DASHBOARD = 'DASHBOARD',
  PROFILE = 'PROFILE',
  RESEARCHERS_SCORE = 'RESEARCHERS_SCORE',
  ALL_RESEARCHERS_SCORE = 'ALL_RESEARCHERS_SCORE',
  RESEARCHERS_SCORE_DETAILS = 'RESEARCHERS_SCORE_DETAILS',
  DROPDOWN_EVENT = 'DROPDOWN_EVENT'
}
