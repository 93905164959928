import React from 'react';
import IconPending from '../../../shared/assets/img/icon-pending.svg';
import IconApproved from '../../../shared/assets/img/icon-approved.svg';
import IconRejected from '../../../shared/assets/img/icon-rejected.svg';
import styled from 'styled-components';

const LegendContainer = styled.div`
  justify-content: flex-end;
  
  & > div {
    margin: auto 20px;
  }
  & > div > span {
    margin: 0 10px;
    position:relative;
    top:1px;
  }
`;

export const TableLegendStatus: React.FC = () => {
  return (
    <LegendContainer className='d-flex'>
      <div>
        Leyenda de status
      </div>
      <div>
        <img src={IconApproved} alt='Icon Approved' /> <span>Aprobado</span>
      </div>
      <div>
        <img src={IconPending} alt='Icon pending' />  <span>En progreso</span>
      </div>
      <div>
        <img src={IconRejected} alt='Icon rejected' />  <span>Rechazado</span>
      </div>
    </LegendContainer>
  );
};