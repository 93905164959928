import React  from 'react';
import { SYSTEM_STATUS, SystemStatus } from '../../constants';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';

const InputStatusColors = styled(Form.Control)`
  border-color: ${(props) => `var(${props.color})`} !important;
  font-weight: 500;
  color: ${(props) => `var(${props.color})`} !important;
  border-width: 2px !important;

  &:focus {
    box-shadow: none !important;
    border-color: ${(props) => `var(${props.color})`} !important;
  }

`;

type InputStatusProps = {
  status: SystemStatus;
}

export const InputStatus: React.FC<InputStatusProps> = ({ status }) => {

  let color = '--bs-warning';
  switch (status) {
    case SystemStatus.APPROVED:
      color = '--bs-success';
      break;
    case SystemStatus.REJECTED:
      color = '--bs-danger';
      break;
    default:
      break;
  }

  const systemStatusLabel = SYSTEM_STATUS.find((ss) => ss.value === status);

  return (
    <InputStatusColors color={color} value={systemStatusLabel?.label ?? SystemStatus.IN_PROGRESS} readOnly />
  );
};