import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useSetupAuth0Token } from './auth-hooks';
// import { Redirect } from '../../shared/components/Redirect';
import { useNavigate } from 'react-router-dom';
import { MainLoader } from '../../shared/components/MainLoader';
import { OnFetchSessionErrorEvent, OnFetchSessionEvent, OnTokenEvent } from './auth-events';
import { fetchSession } from './auth-actions';
import { useEvent, useSubscription } from '@cobuildlab/react-simple-state';
import { currentEvent } from '../events/event-actions';

/**
 * @param {object} props - Props.
 * @param {JSX.Element}  props.children - Children.
 * @returns {JSX.Element} - Session component.
 */
const ApolloSession: React.FC = ({ children }) => {
  const { loading: loadingToken } = useSetupAuth0Token();
  const {user} = useAuth0();
  const [loadingSession, setLoadingSession] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const token = useEvent(OnTokenEvent).token;

  useEffect(() => {
    if (token) {
      fetchSession();
    }
  }, [token]);

  useSubscription(OnFetchSessionEvent, (data) => {
    if(data && data.user){
      const defaultEvent = (data.user.user_events || []).length ? data.user?.user_events?.[0] : undefined;
      const event = (data.user.user_events || []).find((userEvent)=>userEvent.id === data.user?.current_event);
      currentEvent(event || defaultEvent);
    }
    setLoadingSession(false);
  });

  useSubscription(OnFetchSessionErrorEvent, () => {
    setError(true);
  });

  if (loadingToken) return <MainLoader />;

  if (loadingSession) return <MainLoader >Cargando usuario</MainLoader>;


  if (error) {
    //TODO: Mostrar modal con errores
  }

  return <>{children}</>;
};

type SessionProps = {
  children: React.ReactNode;
};

/**
 * @param {object} props - Props.
 * @param {JSX.Element}  props.children - Children.
 * @returns {JSX.Element} - Session component.
 */
export function Session({ children }: SessionProps): JSX.Element {
  const { isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();


  //TODO: create loading app
  if (isLoading) return <MainLoader />;
  if (!isAuthenticated) navigate('auth', { state: { redirectTo: window.location } });

  return <ApolloSession>{children}</ApolloSession>;
}