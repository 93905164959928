import React, { useState } from 'react';
import { TableDefault } from '../../shared/components/Table/TableDefault';
import { UserType } from './user-types';
import { ButtonDefault } from '../../shared/components/Buttons/ButtonDefault';
import { ModalDefault } from '../../shared/components/Modal/ModalDefault';
import { Form } from 'react-bootstrap';
import { ROLES, SystemRoles } from '../../shared/constants';
import Select from 'react-select/base';
import { ReactSelectOption } from '../../shared/components/ReactSelect/ReactSelectOption';
import { useCallAction, useEvent, useFetchAction } from '@cobuildlab/react-simple-state';
import { createUserInvitation, fetchUsers, openInviteUserDialog } from './user-actions';
import { showToast } from '../../shared/components/toast/toast';
import Avatar from 'react-avatar';
import { PaginateAction } from '../../shared/components/paginate/paginate-types';
import { InviteUserDialog } from './components/InviteUserDialog';
import { InviteUserEventDialog } from './components/InviteUserEventDialog';
import { openInviteUserDialogEvent } from './user-events';
import { openInviteUserEventCategoryDialog, openInviteUserEventDialog } from '../events/event-actions';
import { currentEventEvent } from '../events/event-events';
import { TableButtonDefault } from '../../shared/components/Table/TableButtonDefault';
import { InviteUserEventCategoryDialog } from './components/InviteUserEventCategoryDialog';

/**
 *
 @returns {JSX.Element} - Layout component of the app.
 */
export const Users: React.FC = () => {
  const [pagination, setPagination] = useState<PaginateAction>({
    page: 1,
    pageSize: 10,
  });

  const { event } = useEvent(currentEventEvent);

  const [{
    users,
    totalUsers,
  }, isLoading, { refetch }] = useFetchAction(fetchUsers, [pagination.page, pagination.pageSize, event?.id as string]);

  return (
    <>
      <TableDefault
        title='Usuarios'
        items={users}
        isLoading={isLoading}
        pagination={pagination}
        changePage={(page) => setPagination({
          ...pagination,
          page,
        })}
        columns={[
          {
            columnName: '',
            columnValue: null,
            columnComponent: (item: UserType) => (
              <Avatar name={item?.display_name} round='50%' src={item?.avatar} size='40' maxInitials={1} />
            ),
          },
          {
            columnName: 'Nombres y apellidos',
            columnValue: (item: UserType) => item?.display_name ?? '-',
          },
          {
            columnName: 'Email',
            columnValue: (item: UserType) => item.user_email,
          },

          {
            columnName: 'Rol',
            columnValue: null,
            columnComponent: (item: UserType) => (
              <div className='text-capitalize'>{item.role?.replace('_', ' ')}</div>
            ),
          }
        ]}
        totalItems={totalUsers}
        actions={[
          {
            actionName: 'Archivar',
            onClick: () => {
            },
          },
          {
            actionName: 'Convocatoria',
            onClick: (item: UserType) => openInviteUserEventDialog(true, item),
          },
          {
            actionName: 'Categorias',
            onClick: (item: UserType) => openInviteUserEventCategoryDialog(true, item),
          },
        ]}
        cardActions={[
          <TableButtonDefault
            onClick={() => openInviteUserDialog(true)}
          >
            Invitar
          </TableButtonDefault>,
        ]}
      />
      <InviteUserDialog onSuccess={() => refetch()} />
      <InviteUserEventDialog onSuccess={() => refetch()} />
      <InviteUserEventCategoryDialog onSuccess={() => refetch()} />
    </>

  );
};