import React, { ChangeEvent, ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSession } from '../../../modules/auth/auth-hooks';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { changeEvent, currentEvent } from '../../../modules/events/event-actions';
import { currentEventEvent } from '../../../modules/events/event-events';
import styled from 'styled-components';
import { EventType } from '../../../modules/events/event-types';

const Select = styled(Form.Select)`
  border: 2px solid #2F7AC5!important;
  color: #2F7AC5;
`;


export const DropdownEvent: React.FC = () => {

  const [changeCallback] = useCallAction(changeEvent);
  const { user } = useSession();
  const { event } = useEvent(currentEventEvent);
  const onChangeSelect = (_: any): void => {
    changeCallback({
      user_id: user?.ID as string,
      event_id: _.target.value,
    });

    const events = [...user?.user_events || []];
    const selectedEvent = events?.find((e: EventType) => e.id === _.target.value as string);
    currentEvent(selectedEvent);
  };

  if (user?.user_events && !user?.user_events.length) return null;

  return (
    <Form.Group className='m-auto'>
      <Select onChange={onChangeSelect} value={event?.id}>
        {
          (user?.user_events || []).map((ev) => (
            <option value={ev.id}>{ev.name}</option>
          ))
        }
      </Select>
    </Form.Group>
  );
};