import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEvent, useFetchAction } from '@cobuildlab/react-simple-state';
import {
  fetchAllAssignedProjects,
  openProjectObservationDialog,
} from './researcher-actions';
import { TableDefault } from '../../shared/components/Table/TableDefault';
import { ResearcherType } from './researcher-types';
import { ProjectObservationDialog } from './components/ProjectObservationDialog';
import { PaginateAction } from '../../shared/components/paginate/paginate-types';
import { currentEventEvent } from '../events/event-events';
import { useSession } from '../auth/auth-hooks';
import IconRejected from '../../shared/assets/img/icon-rejected.svg';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import 'moment/locale/es';

moment.locale('es');

export const ResearchersAllScoreView: React.FC = () => {
  const navigate = useNavigate();
  const [pagination, setPagination] = useState<PaginateAction>({
    page: 1,
    pageSize: 10,
  });

  const { event } = useEvent(currentEventEvent);
  const { user } = useSession();
  const [columnsCustom, setColumnsCustom] = useState<{
    value: string;
    label: string
  }[]>([]);

  const [{ researchers, totalResearchers }, isLoading] = useFetchAction(
    fetchAllAssignedProjects,
    [pagination.page, pagination.pageSize, event?.id as string],
  );

  useEffect(() => {
    if (user && user.user_events && user.user_events.length) {
      const userEvents = user.user_events.find((userEvent) => userEvent.id === event?.id);
      const fields = userEvents?.fields && userEvents?.fields.length ? userEvents?.fields : [];
      setColumnsCustom(fields);
    }

  }, [user, event]);


  return (
    <>
      <TableDefault
        title='Proyectos'
        items={researchers}
        isLoading={isLoading}
        pagination={pagination}
        changePage={(page) =>
          setPagination({
            ...pagination,
            page,
          })
        }
        columns={[
          {
            columnName: 'ID',
            columnValue: (item: ResearcherType) => item?.ID,
          },
          {
            columnName: 'Total',
            columnValue: null,
            columnComponent: (item: ResearcherType) => (
              <div style={{color:'#bb2124', fontWeight:'bold'}}>{item?.data.total}</div>
            )
          },
          {
            columnName: 'Fecha de Asignación',
            columnValue: (item: ResearcherType) => moment(item.data.created_at).fromNow(),
          },
          {
            columnName: 'Documentos',
            columnValue: null,
            columnComponent: (item: ResearcherType) => {
              return (
                <>
                  <div><a href={item.data.projectFile}>Resumen Ejecutivo</a></div>
                  <div><a href={item.data.approvedLetter}>Carta de aprobación</a></div>
                  <div><a href={item.data.acceptedLetter}>Carta de acceptación</a></div>
                </>

              );
            },
          },
          {
            columnName: 'Título',
            columnValue: (item: ResearcherType) => item?.data.projectTitle,
          },
          {
            columnName: 'Evaluación',
            columnValue: null,
            columnComponent: (item: ResearcherType) => {
              return (
                <div className='row' style={{minWidth:300}}>
                  {
                    item.data.judges?.map((judge) => (
                      <div className='col-md-4'>
                        <div className='text-decoration-underline'>{judge.display_name || judge.user_email}</div>
                        {
                          judge.evaluation ? (
                            columnsCustom.map((column) => {
                              if (judge.evaluation) {
                                return (
                                  <div>
                                    {column.label}: {judge.evaluation[column.value]}
                                  </div>
                                );
                              }
                            })
                          ) : (
                            <div className='m-1'>
                              <OverlayTrigger overlay={<Tooltip id='tooltip-disabled'>Sin Evaluar</Tooltip>}>
                                <img src={IconRejected} alt='icon rejected' />
                              </OverlayTrigger>
                            </div>
                          )
                        }
                      </div>
                    ))
                  }

                </div>
              );
            },
          },

        ]}
        totalItems={totalResearchers}
      />
      <ProjectObservationDialog />
    </>
  );
};
