import React from 'react';
import { IDObj, IdObj, MenuActionType } from './table-types';
import { Dropdown } from 'react-bootstrap';
import { Icon } from '@iconify/react';
import styled from 'styled-components';
import { PRIMARY_COLOR } from '../../constants';

interface TableActionsMenuProps<T> {
  actions: MenuActionType<T>[];
  item: T;
}

const DropdownIconAction = styled(Dropdown.Toggle)`
  border: none;
  color: ${PRIMARY_COLOR} !important;
  background-color: transparent !important;
  padding: 0.5rem;
  box-shadow: none;

  &:focus, &:active, &:before, &:after{
    box-shadow: none!important;
  }

  & > svg {
    fill: ${PRIMARY_COLOR} !important;
  }

  &:hover, &:active, &:focus {
    background-color: transparent;
    color: ${PRIMARY_COLOR};

  }

  &:after {
    display: none
  }
`;

export const TableMenuAction = <T extends IdObj | IDObj>(
  props: React.PropsWithChildren<TableActionsMenuProps<T>>,
): JSX.Element => {

  const {item, actions} = props;

  return (
    <Dropdown>
      <DropdownIconAction id='dropdown-basic'>
        <Icon
          icon='ant-design:plus-outlined'
          width={25}
          height={25}
          color='#2F7AC5'
        />
      </DropdownIconAction>
      <Dropdown.Menu>
        {
          actions.map((action)=>(
            <Dropdown.Item onClick={()=>action.onClick(item)}>
              {typeof action.actionName === 'function'
                ? action.actionName(item)
                : action.actionName}
            </Dropdown.Item>
          ))
        }
      </Dropdown.Menu>
    </Dropdown>
  );
};