import React, { ReactElement } from 'react';
import { Card, CardProps } from 'react-bootstrap';
import styled from 'styled-components';

interface CardDefaultProps extends CardProps {
  title?: string;
  children: ReactElement;
  footer?: ReactElement | null;
  cardActions?: ReactElement[] | undefined | null
}

const CardHeader = styled(Card.Header)`
  padding: 1rem;
  height:80px;
  background: #00185D 0% 0% no-repeat padding-box;
  color:white;  
`;

const CardActionDefault: React.FC<{ cardActions: ReactElement[] }> = (
  { cardActions },
) => (
  <div className='d-flex ms-auto align-items-center'>
    {cardActions.map((cardAction) => (
      <div className='mx-1'>
        {cardAction}
      </div>
    ))}
  </div>
);


export const CardDefault: React.FC<CardDefaultProps> = (
  {
    title,
    children,
    footer,
    cardActions,
    ...rest
  }) => {

  return (
    <Card {...rest}>
      {
        title && (
          <CardHeader className='d-flex align-items-center'>
            <h3 className='mb-0 text-uppercase' style={{letterSpacing:2}}>{title}</h3>
            {cardActions?.length && (<CardActionDefault cardActions={cardActions} />)}
          </CardHeader>
        )
      }
      <Card.Body>
        {children}
      </Card.Body>
      {
        footer && (<Card.Footer>{footer}</Card.Footer>)
      }
    </Card>
  );
};