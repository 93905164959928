import { createAction } from '@cobuildlab/react-simple-state/lib/actions';
import {
  fetchProfileErrorEvent,
  fetchProfileEvent,
  updateProfileErrorEvent,
  updateProfileEvent,
} from './profile-events';
import { OnTokenEvent } from '../auth/auth-events';
import axios from 'axios';
import { API_ENDPOINT } from '../../shared/constants';


export const updateProfile = createAction(
  updateProfileEvent,
  updateProfileErrorEvent, async (values) => {

    const formData = new FormData();

    Object.keys(values).forEach((o) => {
      formData.append(o, values[o as never]);

    });

    const response = await axios.post(`${API_ENDPOINT}/wp-json/wp/v2/tainacan/user/update`, formData,{
      headers: {
        Authorization: `Bearer ${OnTokenEvent.get()?.token as string}`,
        'content-type': 'multipart/form-data',
      },
    });

    return response.data;
  },
);

export const fetchProfile = createAction(
  fetchProfileEvent,
  fetchProfileErrorEvent, async () => {
    const response = await fetch(`${API_ENDPOINT}/wp-json/wp/v2/tainacan/user/info`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${OnTokenEvent.get()?.token as string}`,
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();

    if (!response.ok) {
      throw Error('No se puede obtener la informacion del perfil');
    }
    return { user: json.user };
  },
);