import React from 'react';
import { Modal, ModalProps, Spinner } from 'react-bootstrap';
import { ButtonDefault } from '../Buttons/ButtonDefault';
import { PRIMARY_COLOR } from '../../constants';

interface ModalDefaultProps extends ModalProps {
  isLoading?: boolean;
  showOkButton?: boolean;
  showFooterButtons?: boolean;
  textClose?: string;
  textSave?: string;
  title?: string;
  handleClose?: () => void;
  onSave?: () => void;
}

export const Loading: React.FC = () => {
  return (
    <Spinner
      as='span'
      animation='border'
      size='sm'
      role='status'
      aria-hidden='true'
      className='mx-2'
    />
  );

};

export const ModalDefault: React.FC<ModalDefaultProps> = (
  {
    isLoading,
    children,
    handleClose,
    textClose,
    textSave,
    title,
    onSave,
    disabled,
    showOkButton = true,
    showFooterButtons = true,
    ...rest
  },
) => {
  return (
    <Modal centered {...rest}>
      <Modal.Header onHide={handleClose} className='justify-content-center'>
        <Modal.Title  style={{color:'#2F7AC5'}}>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{padding:'1.5rem'}}>{children}</Modal.Body>
      {
        showFooterButtons && (
          <Modal.Footer className='justify-content-center'>
            <ButtonDefault
              style={{ backgroundColor: PRIMARY_COLOR, color: 'white', minWidth: 100 }}
              onClick={handleClose}
              className='text-uppercase px-4 py-2'
            >
              {textClose ? textClose : 'Close'}
            </ButtonDefault>
            {
              showOkButton && (
                <ButtonDefault
                  className='text-uppercase px-4 py-2'
                  onClick={onSave}
                  disabled={isLoading || disabled}
                >
                  {isLoading && <Loading />}
                  {textSave ? textSave : 'Save Changes'}
                </ButtonDefault>
              )
            }

          </Modal.Footer>
        )
      }

    </Modal>
  );
};