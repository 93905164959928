import { createEvent } from '@cobuildlab/react-simple-state';
import { UserType } from '../users/user-types';

export interface InitialStateType {
  token: null | string;
}

const INITIAL_STATE = {
  token: null,
};

export const OnTokenEvent = createEvent<InitialStateType>({
  initialValue: INITIAL_STATE,
});
export const OnTokenErrorEvent = createEvent<Error>();

export type SessionType = {
  user:UserType | null;
}

export const OnFetchSessionEvent = createEvent<SessionType>({
  initialValue:{
    user:null
  }
});

export const OnFetchSessionErrorEvent = createEvent();