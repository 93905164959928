import React from 'react';

/**
 * @returns {JSX.Element} - Dash board view.
 */
export const Dashboard = (): JSX.Element => {

  return (
    <div></div>
  );
};
