import { createEvent } from '@cobuildlab/react-simple-state';
import { EventType } from './event-types';
import { UserType } from '../users/user-types';

export const fetchEventsEvent = createEvent<{ events: EventType[], totalEvents: number }>({
  initialValue: {
    events: [],
    totalEvents: 0,
  },
});

export const fetchEventsErrorEvent = createEvent<Error>();

export const openInviteUserEventDialogEvent = createEvent<{ isOpen: boolean, user: UserType | undefined }>(
  {
    initialValue: {
      isOpen: false,
      user: undefined,
    },
  },
);

export const openInviteUserEventCategoryDialogEvent = createEvent<{ isOpen: boolean, user: UserType | undefined }>(
  {
    initialValue: {
      isOpen: false,
      user: undefined,
    },
  },
);

export const createInvitationEventEvent = createEvent();
export const createInvitationEventErrorEvent = createEvent<Error>();

export const createInvitationCategoryEventEvent = createEvent();
export const createInvitationCategoryEventErrorEvent = createEvent<Error>();



export const changeEventEvent = createEvent();
export const changeEventErrorEvent = createEvent<Error>();

export const currentEventEvent = createEvent<{ event: EventType | undefined }>({
  initialValue: {
    event: undefined,
  },
});