export const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN || '';
export const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID || '';
export const AUTH0_CLIENT_SECRET = process.env.REACT_APP_AUTH0_CLIENT_SECRET || '';

export const AUTH0_CLIENT_REDIRECT_URI = `${window.location.origin}/auth/callback`;
export const AUTH0_CLIENT_REDIRECT_LOGOUT = `${window.location.origin}/logout`;


export const API_ENDPOINT = process.env.REACT_APP_ENDPOINT || '';

export const PRIMARY_COLOR = '#074ba2';

export enum SystemRoles {
  SUPER_ADMINISTRATOR = 'super_administrador',
  ADMINISTRATOR = 'administrador',
  RESEARCHER = 'investigador',
  JUDGES = 'juez'
}

export enum SystemStatus {
  APPROVED = 'APPROVED',
  IN_PROGRESS = 'IN_PROGRESS',
  REJECTED = 'REJECTED',
}

export type Options = {
  value:string;
  label:string;
}

export const ROLES: Options[] = [
  {
    value: SystemRoles.SUPER_ADMINISTRATOR,
    label: 'Super Administrador',
  },
  {
    value: SystemRoles.ADMINISTRATOR,
    label: 'Administrador',
  },
  {
    value: SystemRoles.JUDGES,
    label: 'Juez',
  }
];

export const SYSTEM_STATUS: Options[] = [
  {
    value: SystemStatus.APPROVED,
    label: 'Aprobado',
  },
  {
    value: SystemStatus.IN_PROGRESS,
    label: 'En progreso',
  },
  {
    value: SystemStatus.REJECTED,
    label: 'Rechazado',
  }
];