import { createAction } from '@cobuildlab/react-simple-state/lib/actions';
import {
  fetchUsersErrorEvent,
  fetchUsersEvent,
  openInviteUserDialogEvent,
  userInvitationErrorEvent,
  userInvitationEvent,
} from './user-events';
import { OnTokenEvent } from '../auth/auth-events';
import { API_ENDPOINT } from '../../shared/constants';
import { createSyncAction } from '../../shared/components/state';
import { UserInvitationType } from './user-types';

export const openInviteUserDialog = createSyncAction(
  openInviteUserDialogEvent, (prev, isOpen: boolean) => ({
    ...prev,
    isOpen,
  }),
);

export const createUserInvitation = createAction(
  userInvitationEvent,
  userInvitationErrorEvent, async (data: UserInvitationType) => {

    const response = await fetch(`${API_ENDPOINT}/wp-json/wp/v2/tainacan/auth0/create-user`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${OnTokenEvent.get()?.token as string}`,
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();

    if (!response.ok) {
      console.log('response', response);
      throw Error('Error al crear la invitation');
    }

    let errors = false;

    if (
      json.data &&
      json.data.errors) {

      if (json.data.errors.existing_user_login &&
        json.data.errors.existing_user_login.length) {
        errors = true;

      }

      if (json.data.errors.existing_user_email &&
        json.data.errors.existing_user_email.length) {
        errors = true;

      }

    }

    if (errors) throw Error('El usuario ya fue invitado');

  },
);


export const fetchUsers = createAction(
  fetchUsersEvent,
  fetchUsersErrorEvent, async (page: number, pageSize: number, event: string) => {

    const response = await fetch(`${API_ENDPOINT}/wp-json/wp/v2/tainacan/users/list?page=${page - 1}&pageSize=${pageSize}&event_id=${event}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${OnTokenEvent.get()?.token as string}`,
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();

    if (!response.ok) {
      throw Error('No se puede obtener los usuarios');
    }

    return { users: json.users, totalUsers: json.total_users };
  },
);