import React, { useEffect, useState } from 'react';
import { Routes as RoutesComponent, Route } from 'react-router-dom';
import { Auth } from './modules/auth/Auth';
import { AuthCallback } from './modules/auth/components/AuthCallback';
import { Logout } from './modules/auth/Logout';
import { Session } from './modules/auth/Session';
import { Auth0ProviderWithHistory } from './modules/auth/Auth0ProviderWithHistory';
import { Layout } from './shared/components/Layout/Layout';
import { Redirect } from './shared/components/Redirect';
import { Dashboard } from './modules/dashboard/DashboardView';
import { Users } from './modules/users/UsersView';
import { ResearchersView } from './modules/researchers/ResearchersView';
import { ResearcherDetailsView } from './modules/researchers/ResearcherDetailsView';
import { EventsView } from './modules/events/EventsView';
import { Profile } from './modules/profile/Profile';
import { RoleAuthorization } from './modules/rbca/rbca-validators';
import { PermissionRbac } from './modules/rbca/permissions';
import { ResearchersScoreView } from './modules/researchers/ResearchersScoreView';
import { useSession } from './modules/auth/auth-hooks';
import { SystemRoles } from './shared/constants';
import { ResearcherScoreDetailsView } from './modules/researchers/ResearcherScoreDetailsView';
import { ResearchersAllScoreView } from './modules/researchers/ResearchersAllScoreView';

/**
 * @returns Routes.
 */
export const Routes: React.FC = () => {
  const [defaultRoute, setDefaultRoute] = useState('/researchers');
  const session = useSession();

  useEffect(() => {
    if (session.user?.role === SystemRoles.JUDGES) {
      setDefaultRoute('/researchers-score');
    }

  }, [session.user?.role]);


  return (
    <Auth0ProviderWithHistory>
      <RoutesComponent>
        <Route path='/auth' element={<Auth />} />
        <Route path='/auth/callback' element={<AuthCallback />} />
        <Route
          path='*'
          element={
            <Session>
              <RoutesComponent>
                <Route path='/logout' element={<Logout />} />
                <Route
                  path='/dashboard'
                  element={
                    <Layout>
                      <Dashboard />
                    </Layout>
                  }
                />
                <Route
                  path='/home'
                  element={<Layout>Home</Layout>}
                />
                <Route
                  path='/profile'
                  element={
                    <Layout>
                      <Profile />
                    </Layout>}
                />
                <Route
                  path='/researchers'
                  element={
                    <Layout>
                      <RoleAuthorization render={() => <ResearchersView />} permission={PermissionRbac.RESEARCHERS} />
                    </Layout>}
                />
                <Route
                  path='/researchers-score'
                  element={
                    <Layout>
                      <RoleAuthorization render={() => <ResearchersScoreView />}
                                         permission={PermissionRbac.RESEARCHERS_SCORE} />
                    </Layout>
                  }
                />
                <Route
                  path='/all-researchers-score'
                  element={
                    <Layout>
                      <RoleAuthorization render={() => <ResearchersAllScoreView />}
                                         permission={PermissionRbac.ALL_RESEARCHERS_SCORE} />
                    </Layout>
                  }
                />
                <Route
                  path='/researcher-score/:id/details'
                  element={
                    <Layout>
                      <RoleAuthorization
                        render={() => <ResearcherScoreDetailsView />
                        }
                        permission={PermissionRbac.RESEARCHERS_SCORE_DETAILS}
                      />
                    </Layout>
                  }
                />
                <Route
                  path='/researchers/:id/details'
                  element={
                    <Layout>
                      <ResearcherDetailsView />
                    </Layout>}
                />
                <Route
                  path='/users'
                  element={
                    <Layout>
                      <RoleAuthorization render={() => <Users />} permission={PermissionRbac.USERS} />
                    </Layout>}
                />
                <Route
                  path='/events'
                  element={
                    <Layout>
                      <EventsView />
                    </Layout>
                  }
                />
                <Route
                  path='/'
                  element={<Redirect to={defaultRoute} />}
                />
              </RoutesComponent>
            </Session>
          }
        />
      </RoutesComponent>
    </Auth0ProviderWithHistory>
  );
};
