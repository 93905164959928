import React from 'react';
import ReactPaginate from 'react-paginate';
import styled from 'styled-components';

const PaginationContainer = styled.div`
  display: flex;
  justify-content:center;
  & > .pagination > .page-item.active{
    & > a{
      background-color: #0077C0;
      border-color: #0077C0;
      color:white;
    }
  }

  & > .pagination{
    margin: 0;
  }

  & > .pagination > .page-item > a{
    color:#0077C0;
    font-weight: 400;
  }
  & > .pagination > .page-item{
    margin: 0 5px;
  }
`;


type PaginationType = {
  pageCount: number;
  pageRangeDisplayed: number;
  onPageChange: (selectedItem: { selected: number })=>void;
}

export const Pagination: React.FC<PaginationType> = ({
  pageCount,
  pageRangeDisplayed,
  onPageChange
}) => {
  return (
    <PaginationContainer>
      <ReactPaginate
        breakLabel='...'
        nextLabel='>>'
        onPageChange={onPageChange}
        pageRangeDisplayed={pageRangeDisplayed}
        pageCount={pageCount}
        previousLabel='<<'
        nextClassName="page-item"
        nextLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        containerClassName="pagination"
        pageClassName='page-item'
        pageLinkClassName='page-link'
        activeClassName='active'
      />
    </PaginationContainer>
  );
};