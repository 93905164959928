import React, { ReactElement } from 'react';
import { Card, CardProps } from 'react-bootstrap';
import styled , {keyframes} from 'styled-components';

interface TableCardDefaultProps extends CardProps {
  title?: string;
  children: ReactElement;
  legend?: ReactElement;
  footer?: ReactElement | null;
  cardActions?: ReactElement[]
}

const TableCardHeader = styled(Card.Header)`
  padding: 1rem;
  height:80px;
  background: #00185D 0% 0% no-repeat padding-box;
  color:white;
`;

const TableCardActionDefault: React.FC<{ cardActions: ReactElement[] }> = (
  { cardActions },
) => (
  <div className='d-flex ms-auto align-items-center'>
    {cardActions.map((cardAction) => (
      <div className='mx-1'>
        {cardAction}
      </div>
    ))}
  </div>
);

import { fadeIn } from 'react-animations';

const fadeInAnimation = keyframes`${fadeIn}`;

const PrincipalCard = styled(Card)`
  animation: .5s ${fadeInAnimation};
`;

export const TableCardDefault: React.FC<TableCardDefaultProps> = (
  {
    title,
    children,
    footer,
    cardActions,
    legend,
    ...rest
  }) => {

  return (
    <PrincipalCard {...rest}>
      {
        title && (
          <TableCardHeader className='d-flex align-items-center'>
            <h3 className='mb-0 text-uppercase' style={{letterSpacing:2}}>{title}</h3>
            {cardActions?.length && (<TableCardActionDefault cardActions={cardActions} />)}
          </TableCardHeader>
        )
      }
      <Card.Body className='p-0'>
        {children}
      </Card.Body>
      {
        footer || legend ? (<Card.Footer>{footer}{legend}</Card.Footer>) : null
      }
    </PrincipalCard>
  );
};