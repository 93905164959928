import { createAction } from '@cobuildlab/react-simple-state/lib/actions';
import { OnTokenEvent } from '../auth/auth-events';
import {
  assignJudgeErrorEvent,
  assignJudgeEvent,
  changeStatusErrorEvent,
  changeStatusEvent, fetchAllAssignedProjectsErrorEvent, fetchAllAssignedProjectsEvent,
  fetchAssignedProjectErrorEvent,
  fetchAssignedProjectEvent,
  fetchAssignedProjectsErrorEvent,
  fetchAssignedProjectsEvent,
  fetchJudgesErrorEvent,
  fetchJudgesEvent,
  fetchResearcherErrorEvent,
  fetchResearcherEvent,
  fetchResearchersErrorEvent,
  fetchResearchersEvent,
  openAssignJudgesDialogEvent,
  openChangeStatusDialogEvent, openProjectObservationDialogEvent, updateScoreErrorEvent, updateScoreEvent,
} from './researcher-events';
import { UserType } from '../users/user-types';
import queryString from 'query-string';
import { createSyncAction } from '../../shared/components/state';
import { ChangeStatusType, ResearcherDataType, ScoreUpdateInput } from './researcher-types';
import { API_ENDPOINT } from '../../shared/constants';


export const openProjectObservationDialog = createSyncAction(
  openProjectObservationDialogEvent, (prev, isOpen: boolean, data: ResearcherDataType | undefined) => ({
    ...prev,
    isOpen,
    data,
  }),
);

export const openChangeStatusDialog = createSyncAction(
  openChangeStatusDialogEvent, (prev, isOpen: boolean, data: { [key: string]: string | number | object }[] | []) => ({
    ...prev,
    isOpen,
    data,
  }),
);

export const openAssignJudgesDialog = createSyncAction(
  openAssignJudgesDialogEvent, (prev, isOpen: boolean, judges: UserType[]) => ({
    ...prev,
    isOpen,
    judges,
  }),
);


export const fetchResearchers = createAction(
  fetchResearchersEvent,
  fetchResearchersErrorEvent, async (page: number, pageSize: number, event_id: string) => {

    const response = await fetch(`${API_ENDPOINT}/wp-json/wp/v2/tainacan/researches/list?page=${page - 1}&pageSize=${pageSize}&event_id=${event_id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${OnTokenEvent.get()?.token as string}`,
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();

    if (!response.ok) {
      throw Error('No se puede obtener los usuarios');
    }

    return { researchers: json.researchers, totalResearchers: json.total_researchers };
  },
);


export const fetchResearcher = createAction(
  fetchResearcherEvent,
  fetchResearcherErrorEvent, async (researcherId: string, eventId: string) => {

    const response = await fetch(`${API_ENDPOINT}/wp-json/wp/v2/tainacan/researcher/info?researcher_id=${researcherId}&event_id=${eventId}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${OnTokenEvent.get()?.token as string}`,
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();
    if (!response.ok) {
      throw Error('No se puede obtener la informacion del usuario');
    }

    console.log('json', json);

    return { researcher: json.researcher };
  },
);


export const fetchJudges = createAction(
  fetchJudgesEvent,
  fetchJudgesErrorEvent, async (roles = [], category = '', event_id = '') => {
    const arrayRoles = JSON.stringify(roles);

    const query = queryString.stringify({ roles: arrayRoles, category, event_id });
    const response = await fetch(`${API_ENDPOINT}/wp-json/wp/v2/tainacan/users/list_by_category?${query}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${OnTokenEvent.get()?.token as string}`,
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();

    if (!response.ok) {
      throw Error('No se puede obtener los usuarios');
    }

    return { judges: json.users, totalUsers: json.total_users };
  },
);

type ChangeStatusInput = { id: string, data: { [key: string]: string | number | object }[] | [] }

export const changeStatus = createAction(
  changeStatusEvent,
  changeStatusErrorEvent, async (data: ChangeStatusInput) => {

    const response = await fetch(`${API_ENDPOINT}/wp-json/wp/v2/tainacan/researcher/change-status`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${OnTokenEvent.get()?.token as string}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const json = await response.json();


    if (!response.ok) {
      throw Error('Error al cambiar el estado!!');
    }

    return json;
  },
);

type AssignJudgeInput = {
  researcher_id: string;
  event_id: string;
  users: { id: number }[];
}

export const assignJudge = createAction(
  assignJudgeEvent,
  assignJudgeErrorEvent, async (data: AssignJudgeInput) => {

    const response = await fetch(`${API_ENDPOINT}/wp-json/wp/v2/tainacan/researcher/assign-judge`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${OnTokenEvent.get()?.token as string}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    const json = await response.json();


    if (!response.ok) {
      throw Error('Error al asignar juez!!');
    }

    return json;
  },
);


export const fetchAssignedProjects = createAction(
  fetchAssignedProjectsEvent,
  fetchAssignedProjectsErrorEvent, async (
    user_id: string,
    page: number,
    pageSize: number,
    event_id: string,
  ) => {

    const response = await fetch(`${API_ENDPOINT}/wp-json/wp/v2/tainacan/researches/assigned-projects?user_id=${user_id}&page=${page - 1}&pageSize=${pageSize}&event_id=${event_id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${OnTokenEvent.get()?.token as string}`,
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();

    if (!response.ok) {
      throw Error('No se puede obtener los usuarios');
    }

    return { researchers: json.researchers, totalResearchers: json.total_researchers };
  },
);


export const fetchAssignedProject = createAction(
  fetchAssignedProjectEvent,
  fetchAssignedProjectErrorEvent, async (researcherId: string, event_id: string, user_id: string) => {

    const response = await fetch(`${API_ENDPOINT}/wp-json/wp/v2/tainacan/assigned-project/info?researcher_id=${researcherId}&event_id=${event_id}&user_id=${user_id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${OnTokenEvent.get()?.token as string}`,
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();
    if (!response.ok) {
      throw Error('No se puede obtener la informacion del participante');
    }
    return { researcher: json.researcher };
  },
);

export const updateScore = createAction(
  updateScoreEvent,
  updateScoreErrorEvent, async (values: { researcher_id: string, data: ScoreUpdateInput, user_id:string }) => {

    const response = await fetch(`${API_ENDPOINT}/wp-json/wp/v2/tainacan/researcher/update-score`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${OnTokenEvent.get()?.token as string}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(values),
    });

    const json = await response.json();


    if (!response.ok) {
      throw Error('Error al evaluar!!');
    }

    return json;
  },
);

export const fetchAllAssignedProjects = createAction(
  fetchAllAssignedProjectsEvent,
  fetchAllAssignedProjectsErrorEvent, async (page: number, pageSize: number, event_id: string) => {

    const response = await fetch(`${API_ENDPOINT}/wp-json/wp/v2/tainacan/researches/assigned-projects/all?page=${page - 1}&pageSize=${pageSize}&event=${event_id}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${OnTokenEvent.get()?.token as string}`,
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();

    if (!response.ok) {
      throw Error('No se puede obtener los usuarios');
    }

    return { researchers: json.researchers, totalResearchers: json.total_researchers };
  },
);