import React from 'react';
import styled from 'styled-components';
import { Sidebar } from './Sidebar';
import { Topbar } from './Topbar';
import { useEvent } from '@cobuildlab/react-simple-state';
import { toggleButtonEvent } from './layout-events';

const MainContainer = styled.main`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
`;

const Container = styled.div`
  width: 100%;
  padding-left: 225px;
  transition: all 600ms ease-in-out;
`;

const SecondContainer = styled.div`
  padding:2.5rem;
`;

/**
 *
 *
 * @param children
 * @returns {JSX.Element} Children element.
 */
export const Layout: React.FC = ({ children }) => {
  const { isOpen } = useEvent(toggleButtonEvent);

  return (
    <MainContainer className=''>
      <Sidebar />
      <Container style={{ paddingLeft: isOpen ? 225 : 0 }}>
        <Topbar />
        <SecondContainer>
          {children}
        </SecondContainer>
      </Container>
    </MainContainer>
  );
};