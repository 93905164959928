import { createEvent } from '@cobuildlab/react-simple-state';
import { UserType } from './user-types';
import { ResearcherDataType } from '../researchers/researcher-types';


export const userInvitationEvent = createEvent();
export const userInvitationErrorEvent = createEvent<Error>();

export const fetchUsersEvent = createEvent<{users:UserType[], totalUsers:number}>({
  initialValue:{
    users:[],
    totalUsers:0
  }
});
export const fetchUsersErrorEvent = createEvent<Error>();

export const openInviteUserDialogEvent = createEvent<{ isOpen: boolean }>(
  {
    initialValue: {
      isOpen: false
    },
  },
);