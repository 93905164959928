import React, { useState } from 'react';
import { EventType } from './event-types';
import { TableDefault } from '../../shared/components/Table/TableDefault';
import styled from 'styled-components';
import { PaginateAction } from '../../shared/components/paginate/paginate-types';
import { useFetchAction } from '@cobuildlab/react-simple-state';
import { fetchEvents } from './event-actions';


const TextBroken = styled.div`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
`;

export const EventsView: React.FC = () => {
  const [pagination, setPagination] = useState<PaginateAction>({
    page: 1,
    pageSize: 12,
  });

  const [{ events, totalEvents }, isLoading] = useFetchAction(fetchEvents, [pagination.page, pagination.pageSize]);

  return <>
    <TableDefault
      title='Concurso'
      items={events}
      isLoading={isLoading}
      pagination={pagination}
      changePage={(page) => setPagination({
        ...pagination,
        page,
      })}
      columns={[
        {
          columnName: 'Nombre',
          columnValue: null,
          columnComponent: (item: EventType) => (
            <div style={{ width: 200 }}>
              {item?.name}
            </div>
          ),
        },
        {
          columnName: 'Descripción',
          columnValue: null,
          columnComponent: (item: EventType) => (
            <TextBroken>
              <div dangerouslySetInnerHTML={{ __html: item?.description as string }}/>
            </TextBroken>
          ),
        },
      ]}
      totalItems={totalEvents}
    />
  </>;
};