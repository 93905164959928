import React, { useState } from 'react';
import { SYSTEM_STATUS, SystemStatus } from '../../constants';
import styled from 'styled-components';
import { Form } from 'react-bootstrap';

const SelectStatusColors = styled(Form.Select)`
  border-color: ${(props) => `var(${props.color})`} !important;
  font-weight: 500;
  color: ${(props) => `var(${props.color})`} !important;
  border-width: 2px !important;
  
  &:focus {
    box-shadow: none !important;
    border-color: ${(props) => `var(${props.color})`} !important;
  }

  & > option {
    font-weight: 500;
  }

  & > option:nth-child(2) {
    color: var(--bs-warning)
  }

  & > option:nth-child(1) {
    color: var(--bs-success)
  }

  & > option:nth-child(3) {
    color: var(--bs-danger)
  }
`;

type SelectStatusProps = {
  status: SystemStatus;
  onChange: (status:SystemStatus)=> void;
}

export const SelectStatus: React.FC<SelectStatusProps> = ({ status, onChange }) => {

  let color = '--bs-warning';
  switch (status) {
    case SystemStatus.APPROVED:
      color = '--bs-success';
      break;
    case SystemStatus.REJECTED:
      color = '--bs-danger';
      break;
    default:
      break;
  }

  return (
    <SelectStatusColors color={color} value={status} onChange={(e:any)=>onChange(e.target.value)}>
      {
        SYSTEM_STATUS.map((systemStatus) => (
          <option value={systemStatus.value}>{systemStatus.label}</option>
        ))
      }
    </SelectStatusColors>
  );
};