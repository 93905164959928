import React from 'react';
import { useCallAction, useEvent } from '@cobuildlab/react-simple-state';
import { openChangeStatusDialogEvent } from '../researcher-events';
import { ModalDefault } from '../../../shared/components/Modal/ModalDefault';
import { changeStatus, openChangeStatusDialog } from '../researcher-actions';
import { Form } from 'react-bootstrap';
import { InputStatus } from '../../../shared/components/Input/InputStatus';
import { SystemStatus } from '../../../shared/constants';
import { useParams } from 'react-router-dom';
import { showToast } from '../../../shared/components/toast/toast';

type ChangeStatusDialogProps = {
  onSuccess: () => void
}

export const ChangeStatusDialog: React.FC<ChangeStatusDialogProps> = (
  {
    onSuccess,
  },
) => {

  const { isOpen, data } = useEvent(openChangeStatusDialogEvent);

  const [changeStatusCallback, isLoading] = useCallAction(changeStatus, {
    onCompleted: () => {
      showToast('success', 'Se cambio el estado de los documentos correctamente!!');
      openChangeStatusDialog(false, []);
      onSuccess();
    },
    onError: (error) => {
      showToast('error', error.message);
    },
  });
  const { id } = useParams<{ id: string }>();


  if (!data) return null;

  return (

    <ModalDefault
      show={isOpen} handleClose={() => openChangeStatusDialog(false, [])}
      title='Cambiar estado de documentos'
      textClose='Cerrar'
      textSave='Cambiar'
      isLoading={isLoading}
      onSave={() => changeStatusCallback({
        id: id as string,
        data,
      })}
    >
      <div className='px-4'>
        <p className='text-center'>¿Deseas cambiar el estado de revisión de los documentos?</p>
        {
          data.map((status, i) => (
            <>
              <Form.Group className='mb-3'>
                <Form.Label>{`${status.label}:`}</Form.Label>
                <InputStatus status={status.value as SystemStatus} />
              </Form.Group>
              {
                status.value as SystemStatus === SystemStatus.REJECTED && (
                  <Form.Group className='mb-3'>
                    <Form.Label>Motivo de rechazo</Form.Label>
                    <Form.Control
                      as='textarea'
                      value={status.reason as string}
                      rows={2}
                      onChange={(e) => {
                        const newStatuses = data;
                        newStatuses[i].reason = e.target.value;
                        openChangeStatusDialog(isOpen, [...newStatuses]);
                      }}
                    />
                  </Form.Group>
                )
              }
            </>
          ))
        }
      </div>
    </ModalDefault>
  );
};