import React from 'react';
import styled from 'styled-components';
import { Icon } from '@iconify/react';
import { useEvent } from '@cobuildlab/react-simple-state';
import { toggleButtonEvent } from './layout-events';
import { toggleButtonAction } from './layout-actions';
import { RoleAuthorization } from '../../../modules/rbca/rbca-validators';
import { PermissionRbac } from '../../../modules/rbca/permissions';
import { DropdownEvent } from '../Dropdown/DropdownEvent';

const MainTopbar = styled.div`
  width: 100%;
  height: 70px;
  border-bottom: 2px solid #2F7AC5;
  display: flex;
  padding: .75rem 1.5rem;
  background-color: white;
  align-items: center;
`;

const ButtonIcon = styled.button`
  background-color: transparent;
  border: 2px solid #2F7AC5;
  padding: 0.5em;
  color: #2F7AC5;
  border-radius: 5px;
`;

export const Topbar: React.FC = () => {

  const { isOpen } = useEvent(toggleButtonEvent);


  return (
    <MainTopbar>
      <ButtonIcon onClick={() => toggleButtonAction(!isOpen)}>
        <Icon
          icon={isOpen ? 'ant-design:menu-fold-outlined' : 'ant-design:menu-unfold-outlined'}
          width={25}
          height={25}
        />
      </ButtonIcon>
      <RoleAuthorization render={
        () => <DropdownEvent />
      } permission={PermissionRbac.DROPDOWN_EVENT} error={() => null} />
    </MainTopbar>
  );
};