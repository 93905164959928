import React from 'react';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';

const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

type LoaderMainType = {
  height?: string | undefined;
  children?: React.ReactNode;
}

export const MainLoader: React.FC<LoaderMainType> = ({ height ,children}) => {
  return (
    <SpinnerContainer style={{height: height ? height : '100vh'}}>
      {children}
      <Spinner animation='border' />
    </SpinnerContainer>);
};