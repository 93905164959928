import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { createAction } from '@cobuildlab/react-simple-state/lib/actions';
import { useLocation, useNavigate } from 'react-router-dom';
import { OnFetchSessionEvent, OnTokenErrorEvent, OnTokenEvent, SessionType } from './auth-events';
import { useEvent, useSubscription } from '@cobuildlab/react-simple-state';

/**
 * @returns {void}
 */
export function useSetupAuth0Token(): {
  loading: boolean;
  isTokenReady: boolean;
} {
  const auth = useAuth0();
  const [loading, setLoading] = useState(false);
  const [isTokenReady, setIsTokenReady] = useState(false);

  useEffect(() => {
    const tokenAction = createAction(
      OnTokenEvent,
      OnTokenErrorEvent,
      async () => {
        const token = await auth.getIdTokenClaims().catch((e) => {
          console.log('auth:hook', e);
        });

        // eslint-disable-next-line no-underscore-dangle
        return { token: token?.__raw as string };
      },
    );

    if (auth.isAuthenticated) {
      setLoading(true);
      tokenAction();
    }
  }, [auth, auth.getIdTokenClaims, auth.isAuthenticated]);
  useSubscription(OnTokenEvent, () => {
    setIsTokenReady(true);
    setLoading(false);
  });
  // TODO: handle the error case when fetching the token
  return { loading, isTokenReady };
}

/**
 * @param {string} route - Default path to redirect.
 * @returns {void}
 */
export function useDefaultRedirect(route: string): void {
  const auth = useAuth0();
  const navegate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (auth.isAuthenticated && location.pathname === '/') {
      navegate(route);
    }
  }, [navegate, auth.isAuthenticated, route, location.pathname]);

  // TODO: hanlde the error case when fetching the token
}

// /**
//  * Hook for returning the User Session.
//  *
//  * @returns {QueryResponse<SessionQuery>} The response.
//  */
// export function useSession(): QueryResponse<SessionQuery> | null {
//   const { loading, error, data, refetch } =
//     useQuery<SessionQuery>(FETCH_SESSION_QUERY);
//   return { loading, error, data, refetch };
// }

export function useSession(): SessionType {
  return useEvent(OnFetchSessionEvent);
}