import { createAction } from '@cobuildlab/react-simple-state/lib/actions';
import {
  changeEventErrorEvent,
  changeEventEvent, createInvitationCategoryEventErrorEvent, createInvitationCategoryEventEvent,
  createInvitationEventErrorEvent,
  createInvitationEventEvent, currentEventEvent,
  fetchEventsErrorEvent,
  fetchEventsEvent, openInviteUserEventCategoryDialogEvent,
  openInviteUserEventDialogEvent,
} from './event-events';
import { API_ENDPOINT } from '../../shared/constants';
import { OnTokenEvent } from '../auth/auth-events';
import { createSyncAction } from '../../shared/components/state';
import { UserType } from '../users/user-types';
import { EventType } from './event-types';

export const openInviteUserEventDialog = createSyncAction(
  openInviteUserEventDialogEvent, (prev, isOpen: boolean, user: UserType | undefined) => ({
    ...prev,
    isOpen,
    user,
  }),
);

export const openInviteUserEventCategoryDialog = createSyncAction(
  openInviteUserEventCategoryDialogEvent, (prev, isOpen: boolean, user: UserType | undefined) => ({
    ...prev,
    isOpen,
    user,
  }),
);

export const currentEvent = createSyncAction(
  currentEventEvent, (prev, event: EventType | undefined) => ({
    ...prev,
    event,
  }),
);

export const fetchEvents = createAction(
  fetchEventsEvent,
  fetchEventsErrorEvent, async (page: number, pageSize: number) => {
    const response = await fetch(`${API_ENDPOINT}/wp-json/wp/v2/tainacan/event/list?page=${page - 1}&pageSize=${pageSize}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${OnTokenEvent.get()?.token as string}`,
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();

    if (!response.ok) {
      throw Error('No se puede obtener los usuarios');
    }

    return { events: json.events, totalEvents: json.total_events };
  },
);


export const createEventInvitation = createAction(
  createInvitationEventEvent,
  createInvitationEventErrorEvent, async (data: { user_id: string; events: { id: string }[] }) => {

    const response = await fetch(`${API_ENDPOINT}/wp-json/wp/v2/tainacan/event/invitation/create`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${OnTokenEvent.get()?.token as string}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      console.log('response', response);
      throw Error('Error al crear la invitation');
    }
  },
);

export const createEventCategoryInvitation = createAction(
  createInvitationCategoryEventEvent,
  createInvitationCategoryEventErrorEvent, async (data: { user_id: string; categories: { value: number, label: string }[] }) => {

    const response = await fetch(`${API_ENDPOINT}/wp-json/wp/v2/tainacan/event/invitation/categories`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${OnTokenEvent.get()?.token as string}`,
        'Content-Type': 'application/json',
      },
    });
    const json = await response.json();

    console.log(json);

    if (!response.ok) {
      console.log('response', response);
      throw Error('Error al asignar categoria');
    }

    return json;
  },
);

export const changeEvent = createAction(
  changeEventEvent,
  changeEventErrorEvent, async (data: { user_id: string; event_id: string }) => {

    const response = await fetch(`${API_ENDPOINT}/wp-json/wp/v2/tainacan/event/current/change`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${OnTokenEvent.get()?.token as string}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      console.log('response', response);
      throw Error('Error al crear la invitation');
    }
  },
);