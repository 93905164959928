import { components } from 'react-select';
import React from 'react';


type ReactSelectOptionType = {
  reactSelectProps: any,
  defaultValues: any
}

/**
 * Custom option element.
 *
 * @param {object}reactSelectProps - Select Props.
 * @param {Array}defaultValues - Default values for async select.
 * @returns {Element} - Option Element.
 */

export const ReactSelectOption = ({ reactSelectProps, defaultValues }: ReactSelectOptionType) : any => {
  const { value } = reactSelectProps;
  const isSelected = defaultValues.find(
    (defaultValue: any) => JSON.stringify(defaultValue.value) === JSON.stringify(value),
  );
  return !isSelected ? <components.Option {...reactSelectProps} /> : null;
};


